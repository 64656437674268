import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './style.scss';

interface Props {
  state?: {};
}

/**
 * ヘッダーの戻るボタン
 * @param amount
 * @returns 日本円表記の金額
 */
export const HeaderBackward: React.FC<Props> = ({
  state,
}: Props): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const [is_disable, setIsDisable] = useState<boolean>(false);

  useEffect(() => {
    switch (location.pathname) {
      case '/password/init':
      case '/maint/input':
      case '/maint/confirm':
      case '/maint/execute':
      case '/maint/result':
        setIsDisable(true);
        break;
      default:
        setIsDisable(false);
        break;
    }
    if (location.pathname.includes('/maint/edit')) {
      setIsDisable(true);
    }
  }, []);

  const on_back = () => {
    switch (location.pathname) {
      case '/maint/input':
        navigate('/home');
        break;
      case '/maint/confirm':
        navigate('/maint/input', { state });
        break;
      case '/maint/execute':
        break;
      case '/maint/result':
        navigate('/maint/input');
        break;
      default:
        navigate(-1);
        break;
    }
  };

  return (
    <div className="header__backword">
      {is_disable ? (
        <p></p>
      ) : (
        <a onClick={on_back}>
          <p>戻る</p>
        </a>
      )}
    </div>
  );
};
