import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { PrivateSite } from 'ui/template/PrivateSite';
import { Col, Container, Row } from 'react-bootstrap';
import { format } from 'date-fns';
import { BucketUrl } from 'core/model/contract/BucketUrl';
import { MaintRequestResponse } from 'core/model/contractors-api/MaintRequest';
import { decrypt } from 'compornents/functions/util/Crypt';
import { DISTRIBUTOR } from 'core/const/Distributor';

export const MaintRequestEditInit = () => {
  const navigate = useNavigate();
  const { request_id } = useParams();
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [distributor, setDistributor] = useState<number>();
  const bucket = process.env.REACT_APP_S3_BUCKET || '';

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
      setDistributor(cookieInfo.distributor);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<MaintRequestResponse[]>({
          path: CONTRACTORS_API_PATH.MAINT_REQUEST,
          params: { member_id, contract_id, request_id },
          member_id,
        });

        if (await api.get()) {
          const response = api.getResponse();
          if (response && response.length === 1) {
            const maint = response[0];

            const item_ids: string[] = [];
            maint.items.forEach((item) => {
              item_ids.push(String(item.contract_maint_item_id));
            });

            const receiptFiles: File[] = [];
            for await (const receipt of maint.receipts) {
              const api = new ContractAPI<BucketUrl>({
                path: CONTRACTORS_API_PATH.BUCKET,
                params: {
                  member_id,
                  bucket,
                  file_path: receipt.file_path,
                  expire: 3600,
                },
                member_id,
              });
              if (await api.get()) {
                const response = api.getResponse();
                if (response) {
                  const splitPath = receipt.file_path.split('/');
                  const fileName = splitPath[splitPath.length - 1];
                  const obj = await fetch(response.geturl)
                    .then((response) => response.blob())
                    .then(
                      (blob) =>
                        new File([blob], fileName, { type: 'image/png' }),
                    );
                  receiptFiles.push(obj);
                }
              }
            }

            if (distributor === DISTRIBUTOR.OAC) {
              navigate(`/maint/edit/${request_id}/input`, {
                state: {
                  item_ids,
                  receiptFiles,
                  use_date: format(
                    new Date(maint.request.use_date),
                    'yyyy-MM-dd',
                  ),
                },
              });
            } else {
              navigate(`/maint/edit/${request_id}/input`, {
                state: {
                  item_ids,
                  receiptFiles,
                  use_date: format(
                    new Date(maint.request.use_date),
                    'yyyy-MM-dd',
                  ),
                  is_registered_bank: false,
                  bank_name: maint.request.bank_name,
                  branch_name: maint.request.branch_name,
                  deposit_code: maint.request.deposit_code,
                  account_holder: maint.request.account_holder,
                  account_number: decrypt(
                    maint.request.account_number?.toString() || '',
                    process.env.REACT_APP_CRYPT_ACCOUNT_NUMBER || '',
                    process.env.REACT_APP_CRYPT_SALT || '',
                    process.env.REACT_APP_CRYPT_IV || '',
                  ),
                },
              });
            }
          }
        }
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="処理中" loading={true}>
      <Container>
        <Row>
          <Col>
            <p>請求を編集する準備をしています。</p>
          </Col>
        </Row>
      </Container>
    </PrivateSite>
  );
};
