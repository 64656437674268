import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { BannerInfo } from 'core/model/contract/BannerInfo';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { PartLoadingView } from '../Loading/Part';
import './style.scss';

export const Banner = (): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [banners, setBanners] = useState<BannerInfo[]>([]);
  const [member_id, setMemberId] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const service_url = process.env.REACT_APP_SERVICE_URL || '';

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<BannerInfo[]>({
          path: CONTRACTORS_API_PATH.BANNER,
          params: {},
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            setBanners(response);
          }
        }
        setLoaded(true);
      })();
    }
  }, [member_id]);

  return (
    <Container className="banner">
      {!loaded ? (
        <PartLoadingView />
      ) : (
        <>
          {banners.length > 0 && (
            <>
              {banners.map((row) => {
                return (
                  <Row key={`banner-${row.banner_id}`}>
                    <Col>
                      <a href={row.banner_link}>
                        <img
                          src={`${service_url}/${row.banner_path}`}
                          alt={row.banner_name}
                        />
                      </a>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </>
      )}
    </Container>
  );
};
