import React from 'react';
import ReactDOM from 'react-dom/client';
import liff from '@line/liff';
import './index.css';
import App from './App';

liff
  .init({ liffId: process.env.REACT_APP_LIFF_ID || '' })
  .then(() => {
    const root = ReactDOM.createRoot(
      document.getElementById('root') as HTMLElement,
    );
    root.render(<App />);
  })
  .catch((e) => {
    alert(`LIFF error: ${e.message}`);
  });
