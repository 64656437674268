import React, { useEffect, useState } from 'react';

interface Props {
  amount?: number;
}

/**
 * 3桁カンマ区切りの金額表示
 * @param amount
 * @returns 日本円表記の金額
 */
export const Amount: React.FC<Props> = ({
  amount,
}: Props): React.ReactElement => {
  const [formatted, setFormatted] = useState<string>('---');
  useEffect(() => {
    if (amount) {
      setFormatted(String(amount).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,'));
    }
  });
  return <span>¥ {formatted}</span>;
};
