import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { PrivateSite } from 'ui/template/PrivateSite';
import { Col, Container, Row } from 'react-bootstrap';
import { DeleteApiResponse } from 'core/model/contractors-api/MaintRequest';
import { RESULT_CODE } from 'core/const/ResultCode';

export const MaintRequestCancel = () => {
  const navigate = useNavigate();
  const { request_id } = useParams();
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<DeleteApiResponse>({
          path: CONTRACTORS_API_PATH.MAINT_REQUEST,
          params: { contract_id, request_id },
          member_id,
        });

        if (await api.delete()) {
          navigate('/maint', {
            state: {
              code: RESULT_CODE.MAINT_REQUEST_CANCELED,
            },
          });
        }
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="処理中" loading={true}>
      <Container>
        <Row>
          <Col>
            <p>請求を取り下げています。</p>
          </Col>
        </Row>
      </Container>
    </PrivateSite>
  );
};
