export const RESULT_CODE = {
  LOGIN_MISTAKE: 'LG001',
  USER_LOCK: 'LG002',
  LOGOUT: 'LG003',
  SESSION_ERROR: 'LG004',
  LOST_SESSION: 'LG005',
  LOGIN_EXPIRED_ENDED: 'LG006',
  LOGIN_EXPIRED_CANCELED: 'LG007',
  LOGIN_SELECT_MISTAKE: 'LG008',
  CONTRACT_NOT_FOUND: 'LG009',
  LIGIN_SYSTEM_ERROR: 'LG999',
  MAINT_REQUEST_NOTFOUND: 'MT901',
  MAINT_REQUEST_CREATED: 'MT001',
  MAINT_REQUEST_UPDATED: 'MT002',
  MAINT_REQUEST_CREATE_FAILED: 'MT003',
  MAINT_REQUEST_UPDATE_FAILED: 'MT004',
  MAINT_REQUEST_CANCELED: 'MT005',
  PASSWORD_CHANGED: 'PW001',
  PASSWORD_CHANGE_FAILED: 'PW002',
  UNMATCH_PASSWORD: 'PW003',
  SAME_PASSWORD: 'PW004',
  RESET_MAIL_SEND_FAILED: 'RE001',
  RESET_VERIFY_FAILED: 'RE002',
  RESET_PASSWORD_CHANGED: 'RE003',
  RESET_PASSWORD_CHANGE_FAILED: 'RE004',
  BACKDOOR_LOGIN_MISTAKE: 'BD001',
  BACKDOOR_CANNOT_EXECUTE: 'BD002',
  SYSTEM_ERROR: 'SYS001',
};
