import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { PrivateSite } from 'ui/template/PrivateSite';
import { RESULT_CODE } from 'core/const/ResultCode';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { ContractAPI } from 'compornents/classes/ContractAPI';

export const Logout: React.FC = () => {
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI({
          path: CONTRACTORS_API_PATH.AUTH_LOGOUT,
          member_id,
        });
        await api.put();
      })();
      removeCookie('contract');
      navigate('/login', { state: { code: RESULT_CODE.LOGOUT } });
    }
  }, [member_id]);

  return <PrivateSite title="ログアウト中..." loading={true} />;
};
