import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { useFormik } from 'formik';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { encrypt } from 'compornents/functions/util/Crypt';
import { format } from 'date-fns';
import { RESULT_CODE } from 'core/const/ResultCode';
import { PublicSite } from 'ui/template/PublicSite';
import './style.scss';

export const PasswordResetSetting: React.FC = () => {
  const navigate = useNavigate();
  const pathParams = useParams();
  const reset_uuid = pathParams.reset_uuid;
  const [code, setCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    if (reset_uuid) {
      (async () => {
        const api = new ContractAPI({
          path: CONTRACTORS_API_PATH.MEMBER_SESSION_RESET,
          params: { reset_uuid },
        });
        const result = await api.get();
        if (!result.success) {
          navigate('/login', {
            state: { code: RESULT_CODE.RESET_VERIFY_FAILED },
          });
        }
      })();
    }
    setLoading(false);
  }, []);

  const onSubmit = async (formInputs: {}) => {
    setLoading(true);
    const params = JSON.parse(JSON.stringify(formInputs));
    const api = new ContractAPI({
      path: CONTRACTORS_API_PATH.MEMBER_SESSION_RESET,
      params: {
        auth: encrypt(
          [
            reset_uuid,
            params.new_password,
            format(new Date(), 'yyyyMMddHHmmss'),
          ].join(','),
          process.env.REACT_APP_CRYPT_API || '',
          process.env.REACT_APP_CRYPT_SALT || '',
          process.env.REACT_APP_CRYPT_IV || '',
        ),
      },
    });
    const result = await api.put();
    if (result.success) {
      navigate('/login', {
        state: { code: RESULT_CODE.RESET_PASSWORD_CHANGED },
      });
    } else {
      setCode(RESULT_CODE.RESET_PASSWORD_CHANGE_FAILED);
    }
    setLoading(false);
  };

  const validationSchema = yup.object().shape({
    new_password: yup
      .string()
      .min(8, '8文字以上で入力してください。')
      .matches(/^[a-zA-Z0-9]+$/, '半角英数字で入力してください。')
      .required('必須項目です。'),
    new_password_confirm: yup
      .string()
      .min(8, '8文字以上で入力してください。')
      .matches(/^[a-zA-Z0-9]+$/, '半角英数字で入力してください。')
      .required('必須項目です。')
      .oneOf([yup.ref('new_password'), ''], '新しいパスワードが一致しません。'),
  });

  const formik = useFormik({
    initialValues: {
      new_password: '',
      new_password_confirm: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <PublicSite title="パスワードリセット" code={code} loading={loading}>
      <Container>
        <Row className="row__page-description">
          <Col>
            <p>新しいパスワードを入力してください。</p>
          </Col>
        </Row>
      </Container>

      <Form noValidate onSubmit={formik.handleSubmit}>
        <Container>
          <Row className="row__form new-password">
            <Col>
              <Form.Group controlId="new_password">
                <Form.Label>新しいパスワード</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="****"
                  isInvalid={
                    !!formik.touched.new_password &&
                    !!formik.errors.new_password
                  }
                  {...formik.getFieldProps('new_password')}
                />
                {formik.touched.new_password && formik.errors.new_password && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.new_password}
                  </Form.Control.Feedback>
                )}
                <div className="password-condition">
                  <p>【パスワード条件】</p>
                  <ul>
                    <li>半角英数字のみ使用可能（a～z・A～Z・0～9）</li>
                    <li>8文字以上</li>
                  </ul>
                </div>
              </Form.Group>
            </Col>
          </Row>

          <Row className="row__form">
            <Col>
              <Form.Group controlId="new_password_confirm">
                <Form.Label>新しいパスワード（確認用）</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="****"
                  isInvalid={
                    !!formik.touched.new_password_confirm &&
                    !!formik.errors.new_password_confirm
                  }
                  {...formik.getFieldProps('new_password_confirm')}
                />
                {formik.touched.new_password_confirm &&
                formik.errors.new_password_confirm ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.new_password_confirm}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row className="row__submit-button">
            <Col>
              <Button type="submit">送信</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </PublicSite>
  );
};
