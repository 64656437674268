import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

/**
 * 認証情報を忘れた場合
 */
export const LostPassword: React.FC = (): React.ReactElement => {
  return (
    <Container>
      <Row className="row__link mb-3">
        <Col>
          <Link to="/reset">パスワードをお忘れの方はこちら</Link>
        </Col>
      </Row>
      <Row className="row__link">
        <Col>
          <Link to="/contact">
            メールアドレス・パスワードどちらもお忘れの方はこちら
          </Link>
        </Col>
      </Row>
    </Container>
  );
};
