import React, { useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row, Table } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { Member } from 'core/model/contract/Member';
import { Contract, ContractDetail } from 'core/model/contract/Contract';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { ContractCustom } from 'core/model/contract/ContractCustom';
import { ContractCarmoOption } from 'core/model/contract/ContractCarmoOption';
import { ContractVehicle } from 'core/model/contract/ContractVehicle';
import { MaintPlan } from 'core/model/contract/MaintPlan';
import { PrivateSite } from 'ui/template/PrivateSite';
import { ContractBankView } from 'ui/organism/ContractBank';
import './style.scss';
import { Amount } from 'ui/molecule/Amount';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { ContractAmount } from 'core/model/contract/ContractAmount';
import { AmountSummary } from 'ui/organism/AmountSummary';
import { DISTRIBUTOR } from 'core/const/Distributor';
import { RESULT_CODE } from 'core/const/ResultCode';
import { ContractWarranty } from 'core/model/contract/ContractWarranty';
import { WarrantyDocuments } from 'ui/organism/Contract/Warranty';

/**
 * お客様情報確認
 *
 * @returns ReactElement
 */
export const ContractConfirm = () => {
  const navigate = useNavigate();
  const [member, setMember] = useState<Member>();
  const [contract, setContract] = useState<Contract>();
  const [amounts, setAmounts] = useState<ContractAmount[]>([]);
  const [customs, setCustoms] = useState<ContractCustom[]>([]);
  const [carmoOptions, setCarmoOptions] = useState<ContractCarmoOption[]>([]);
  const [vehicle, setVehicle] = useState<ContractVehicle>();
  const [warranty, setWarranty] = useState<ContractWarranty[]>([]);
  const [maintPlan, setMaintPlan] = useState<MaintPlan>();
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [distributor, setDistributor] = useState<number>();
  const [expired, setExpired] = useState<boolean>(false);

  /**
   * ログイン情報を取得
   */
  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
      setDistributor(cookieInfo.distributor);
    }
  }, []);

  /**
   * 会員情報の取得
   */
  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<Member>({
          path: CONTRACTORS_API_PATH.MEMBER,
          params: { member_id },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          setMember(api.getResponse());
        } else if (result.statusCode === 401) {
          setExpired(true);
        } else {
          navigate('/login', { state: { code: RESULT_CODE.LOST_SESSION } });
        }
      })();
    }
  }, [member_id]);

  /**
   * 契約情報の取得
   */
  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<ContractDetail[]>({
          path: CONTRACTORS_API_PATH.CONTRACT_DETAIL,
          params: {
            member_id,
            contract_id,
          },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response && response.length === 1) {
            setContract(response[0].contract);
            setAmounts(response[0].amount);
            setVehicle(response[0].vehicle);
            setCustoms(response[0].custom);
            setCarmoOptions(response[0].carmo_option);
            setWarranty(response[0].warranty);
            setMaintPlan(response[0].maint_plan);
          } else {
            navigate('/home', { state: { code: RESULT_CODE.SYSTEM_ERROR } });
          }
        } else if (result.statusCode === 401) {
          setExpired(true);
        }
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="お客様情報確認" expired={expired}>
      <Container>
        <Row>
          <Col>
            <div className="headline">お客様情報</div>
          </Col>
        </Row>

        {!member ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <dl>
                <dt>氏名</dt>
                <dd>
                  {member.last_name} {member.first_name}
                </dd>
                <dt>フリガナ</dt>
                <dd>
                  {member.last_name_kana} {member.first_name_kana}
                </dd>
                <dt>メールアドレス</dt>
                <dd>{member.email}</dd>
                <dt>電話番号</dt>
                <dd>{member.phone_number}</dd>
                <dt>携帯番号</dt>
                <dd>{member.mobile_number}</dd>
                <dt>現住所</dt>
                <dd>
                  {member.address1}
                  {member.address2}
                  {member.address3}
                  {member.address4}
                </dd>
              </dl>
            </Col>
          </Row>
        )}
      </Container>

      {distributor === DISTRIBUTOR.OAC && <ContractBankView />}

      <Container>
        <Row>
          <Col>
            <div className="headline">車両情報</div>
          </Col>
        </Row>

        {!vehicle ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <dl>
                <dt>メーカー</dt>
                <dd>{vehicle.maker_name}</dd>
                <dt>車種名</dt>
                <dd>{vehicle.car_name}</dd>
                <dt>グレード</dt>
                <dd>{vehicle.grade_name}</dd>
                <dt>ボディカラー</dt>
                <dd>{vehicle.body_color}</dd>

                {customs.length > 0 && (
                  <>
                    <dt>オプション装備</dt>
                    <dd>
                      <ListGroup>
                        {customs.map((row, index) => {
                          return (
                            <ListGroup.Item key={`custom-${index}`}>
                              <Row>
                                <Col>
                                  <p className="list">{row.product_name}</p>
                                </Col>
                                <Col>
                                  <p>{row.product_detail}</p>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </dd>
                  </>
                )}

                {carmoOptions.length > 0 && (
                  <>
                    <dt>カルモオプション</dt>
                    <dd>
                      <ListGroup>
                        {carmoOptions.map((row, index) => {
                          return (
                            <ListGroup.Item key={`carmo-${index}`}>
                              <Row>
                                <Col>
                                  <p>{row.name}</p>
                                </Col>
                                <Col>
                                  <p>{row.detail}</p>
                                </Col>
                              </Row>
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </dd>
                  </>
                )}
                {vehicle.inspection_expire_date && (
                  <>
                    <dt>車検満了日</dt>
                    <dd>{vehicle.inspection_expire_date}</dd>
                  </>
                )}
              </dl>
            </Col>
          </Row>
        )}
      </Container>

      <Container>
        <Row>
          <Col>
            <div className="headline">契約情報</div>
          </Col>
        </Row>

        {!contract ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <dl>
                <dt>契約期間</dt>
                <dd>{contract.contract_months}ヶ月</dd>
                <dt>リース開始日</dt>
                <dd>{contract.lease_date_from}</dd>
                <dt>リース終了日</dt>
                <dd>{contract.lease_date_to}</dd>

                {maintPlan && (
                  <>
                    <dt>メンテナンスプラン</dt>
                    <dd>{maintPlan.name}</dd>
                  </>
                )}
              </dl>
            </Col>
          </Row>
        )}
      </Container>

      {warranty.length > 0 && (
        <Container>
          <Row>
            <Col>
              <div className="headline">車両保証</div>
            </Col>
          </Row>

          <dl>
            {warranty.map((row, index) => {
              return (
                <React.Fragment key={`carmo-${index}`}>
                  <dt>{row.name}</dt>
                  <dd>
                    <div className="warranty-detail">
                      <Table>
                        <tbody>
                          <tr>
                            <th>加入日</th>
                            <td>{row.contract_date}</td>
                          </tr>
                          <tr>
                            <th>保証期間</th>
                            <td>
                              {row.warranty_date_from} 〜 {row.warranty_date_to}
                            </td>
                          </tr>
                          {row.disclaimer_date_from &&
                            row.disclaimer_date_to && (
                              <tr>
                                <th>免責期間</th>
                                <td>
                                  {row.disclaimer_date_from} 〜{' '}
                                  {row.disclaimer_date_to}
                                </td>
                              </tr>
                            )}
                          {row.mileage && (
                            <tr>
                              <th>契約時の走行距離</th>
                              <td>{row.mileage.toLocaleString()} km</td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>

                    <WarrantyDocuments
                      vehicle_warranty_id={row.vehicle_warranty_id}
                    />
                  </dd>
                </React.Fragment>
              );
            })}
          </dl>
        </Container>
      )}

      <Container>
        <Row>
          <Col>
            <div className="headline">毎月のお支払い料金(税込)</div>
          </Col>
        </Row>

        {!contract ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <div className="amount-per-month">
                <Amount amount={contract.lease_amount} />
              </div>
            </Col>
          </Row>
        )}
      </Container>

      {amounts.length === 0 ? (
        <PartLoadingView />
      ) : (
        <AmountSummary amounts={amounts} />
      )}
    </PrivateSite>
  );
};
