import React from 'react';
import './style.scss';
import { Col, Container, Row } from 'react-bootstrap';

/**
 * 「Loading...」を全画面表示
 */
export const PartLoadingView: React.FC = () => {
  return (
    <Container className="part-loading">
      <Row>
        <Col>
          <div className="circle" />
          <div className="circle" />
          <div className="circle" />
          <div className="circle" />
          <div className="circle" />
        </Col>
      </Row>
    </Container>
  );
};
