import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { PrivateSite } from 'ui/template/PrivateSite';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { format } from 'date-fns';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { PutApiResponse } from 'core/model/contractors-api/APIResult';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { encrypt } from 'compornents/functions/util/Crypt';
import { RESULT_CODE } from 'core/const/ResultCode';

type NavigateState = {
  old_password: string;
  new_password: string;
};

export const PasswordSettingExecute: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const [member_id, setMemberId] = useState<string>();
  const [cookies] = useCookies(['contract']);
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const auth = [
          navigateState.old_password,
          navigateState.new_password,
          format(new Date(), 'yyyyMMddHHmmss'),
        ];
        const api = new ContractAPI<PutApiResponse>({
          path: CONTRACTORS_API_PATH.MEMBER_SESSION,
          params: {
            auth: encrypt(
              auth.join(','),
              process.env.REACT_APP_CRYPT_API || '',
              process.env.REACT_APP_CRYPT_SALT || '',
              process.env.REACT_APP_CRYPT_IV || '',
            ),
          },
          member_id,
        });
        const result = await api.put();
        if (result.success && result.statusCode === 200) {
          navigate('/home', {
            state: { code: RESULT_CODE.PASSWORD_CHANGED },
          });
        } else if (result.statusCode === 401) {
          setExpired(true);
        } else {
          if (result.code === 'E003') {
            navigate('/password/setting', {
              state: { code: RESULT_CODE.UNMATCH_PASSWORD },
            });
          } else if (result.code === 'E004') {
            navigate('/password/setting', {
              state: { code: RESULT_CODE.SAME_PASSWORD },
            });
          } else {
            navigate('/password/setting', {
              state: { code: RESULT_CODE.PASSWORD_CHANGE_FAILED },
            });
          }
        }
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="処理中" loading={true} expired={expired}>
      <Container>
        <Row>
          <Col>
            <p>パスワード変更を行なっています。</p>
          </Col>
        </Row>
      </Container>
    </PrivateSite>
  );
};
