import React, { useEffect, useState } from 'react';
import { BILLING_STATUS } from 'core/const/BillingStatus';

interface Props {
  status: number;
}

/**
 * 支払ステータス
 */
export const BillingStatus: React.FC<Props> = ({
  status,
}: Props): React.ReactElement => {
  const [text, setText] = useState<string>('---');
  useEffect(() => {
    switch (status) {
      case BILLING_STATUS.UNPROCESSD:
        setText('予定');
        break;
      case BILLING_STATUS.ALL_INCOMPLETE:
      case BILLING_STATUS.PART_INCOMPLETE:
      case BILLING_STATUS.RECEIVABLE:
        setText('確認中');
        break;
      case BILLING_STATUS.COMPLETE:
        setText('済');
        break;
      case BILLING_STATUS.CARMO_COMPLETE:
        setText('カルモ分済');
        break;
    }
  });
  return <span>{text}</span>;
};
