import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PublicSite } from 'ui/template/PublicSite';

export const PasswordResetResult: React.FC = () => {
  return (
    <PublicSite title="メールを送信しました">
      <Container>
        <Row className="row__page-description">
          <Col>
            <p>パスワード再設定メールが届いているかご確認ください。 </p>
          </Col>
        </Row>

        <Row className="row__link-button">
          <Col>
            <Link to="/login">ログイン画面に戻る</Link>
          </Col>
        </Row>
      </Container>
    </PublicSite>
  );
};
