export const props = {
  SERVICE_URL: process.env.REACT_APP_SERVICE_URL || 'https://app.carmo-kun.jp',
  CONTRACTORS_API:
    process.env.REACT_APP_CONTRACTORS_API || 'https://app.carmo-kun.jp/api/',
  S3_BUCKET: process.env.REACT_APP_S3_BUCKET || '',
  S3_RECEIPT_DIR: process.env.REACT_APP_S3_RECEIPT_DIR || 'private/receipts',
  LIFF_ID: process.env.REACT_APP_LIFF_ID || '',
  LIFF_CLIENT_ID: process.env.REACT_APP_LIFF_CLIENT_ID || '',
  CRYPT_COOKIE: process.env.REACT_APP_CRYPT_COOKIE || '',
  CRYPT_API: process.env.REACT_APP_CRYPT_API || '',
  CRYPT_ACCOUNT_NUMBER: process.env.REACT_APP_CRYPT_ACCOUNT_NUMBER || '',
  CRYPT_SALT: process.env.REACT_APP_CRYPT_SALT || '',
  CRYPT_IV: process.env.REACT_APP_CRYPT_IV || '',
  MAINT_ITEM_PREFIX: process.env.REACT_APP_MAINT_ITEM_PREFIX || 'maint_item',
  MAX_RECEPT_FILES: process.env.REACT_APP_MAX_RECEPT_FILES || 5,
  IS_SYSTEM_MAINTENANCE: process.env.REACT_APP_IS_SYSTEM_MAINTENANCE || 'false',
  SYSTEM_MAINTENANCE_FROM: process.env.REACT_APP_SYSTEM_MAINTENANCE_FROM || '',
  SYSTEM_MAINTENANCE_TO: process.env.REACT_APP_SYSTEM_MAINTENANCE_TO || '',
  APP_MODE: process.env.REACT_APP_MODE || 'contractors',
};
