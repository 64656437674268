import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { PrivateSite } from 'ui/template/PrivateSite';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { useCookies } from 'react-cookie';
import { ContractMaintMerchant } from 'core/model/contract/ContractMaintMerchant';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';

export const ContractMaintMerchantPage: React.FC = (): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [merchant, setMerchant] = useState<ContractMaintMerchant>();
  const [loaded, setLoaded] = useState<boolean>(false);
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
      setContractId(cookieContract.contract_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<ContractMaintMerchant>({
          path: CONTRACTORS_API_PATH.CONTRACT_MAINT_MERCAHNT,
          params: { contract_id },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            setMerchant(response);
          }
        } else if (result.statusCode === 401) {
          setExpired(true);
        }
        setLoaded(true);
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="メンテナンス指定工場" expired={expired}>
      <Container>
        {!loaded ? (
          <PartLoadingView />
        ) : (
          <>
            {merchant ? (
              <>
                <Row>
                  <Col>
                    <div className="headline">店舗情報</div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <dl>
                      <dt>対応業者名</dt>
                      <dd>{merchant.merchant_name}</dd>
                      <dt>郵便番号</dt>
                      <dd>{merchant.zip}</dd>
                      <dt>住所</dt>
                      <dd>{merchant.address}</dd>
                      <dt>工場までの距離</dt>
                      <dd>{merchant.merchant_distance}</dd>
                      <dt>営業時間</dt>
                      <dd>{merchant.business_hour}</dd>
                      <dt>定休日</dt>
                      <dd>{merchant.regular_holiday}</dd>
                    </dl>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="headline">問い合わせ先</div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <dl>
                      <dt>電話番号</dt>
                      <dd>{merchant.phone_number}</dd>
                      <dt>担当者名</dt>
                      <dd>{merchant.contact_person}</dd>
                    </dl>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="headline">その他</div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <dl>
                      <dt>備考</dt>
                      <dd>{merchant.note}</dd>
                    </dl>
                  </Col>
                </Row>
              </>
            ) : (
              <Row>
                <Col>
                  <p>メンテナンス指定工場の情報が見つかりません。</p>
                </Col>
              </Row>
            )}
          </>
        )}
      </Container>
    </PrivateSite>
  );
};
