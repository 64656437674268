import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { uploadToS3 } from 'compornents/functions/util/UplaodToS3';
import { RequestStep } from 'ui/organism/RequestStep';
import { PrivateSite } from 'ui/template/PrivateSite';
import { encrypt } from 'compornents/functions/util/Crypt';
import { masking } from 'compornents/functions/util/Masking';
import { Col, Container, Row } from 'react-bootstrap';
import { PostApiResponse } from 'core/model/contractors-api/MaintRequest';
import { RESULT_CODE } from 'core/const/ResultCode';

type NavigateState = {
  item_ids: string[];
  receiptFiles: File[];
  use_date: string;
  is_registered_bank: boolean;
  bank_name?: string;
  branch_name?: string;
  deposit_code?: number;
  account_holder?: string;
  account_number?: string;
};

export const MaintRequestCreateExecute = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const [cookies] = useCookies(['contract']);
  const [receipt_file_paths, setReseptFilePaths] = useState<string[]>([]);
  const [isInit, setIsInit] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [isRequestBank, setIsRequestBank] = useState<boolean>(false);
  const [isStratRequest, setIsStartrequest] = useState<boolean>(false);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [distributor, setDistributor] = useState<number>();
  const [item_ids, setItemIds] = useState<number[]>();
  const [expired, setExpired] = useState<boolean>(false);

  /**
   * ログイン情報を取得
   */
  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
      setDistributor(cookieInfo.distributor);
    }

    // チェックボックスの値が文字列なので数値に変換
    const ids_tmp: number[] = [];
    navigateState.item_ids.forEach((item_id) => {
      ids_tmp.push(Number(item_id));
    });
    setItemIds(ids_tmp);
    setIsInit(true);
  }, []);

  /**
   * s3にファイルをアップロード
   */
  useEffect(() => {
    if (isInit) {
      (async () => {
        if (member_id) {
          const receipt_file_paths = await uploadToS3({
            files: navigateState.receiptFiles,
            member_id,
          });
          if (receipt_file_paths.length === 0) {
            navigate('/maint/input', {
              state: { code: RESULT_CODE.MAINT_REQUEST_CREATE_FAILED },
            });
          }
          setReseptFilePaths(receipt_file_paths);
          setIsUpload(true);
        }
      })();
    }
  }, [isInit]);

  /**
   * 口座情報の登録
   */
  useEffect(() => {
    if (isInit) {
      if (distributor === 1) {
        setIsRequestBank(true);
      } else {
        if (navigateState.is_registered_bank) {
          setIsRequestBank(true);
        } else {
          (async () => {
            const account_number = navigateState.account_number;
            const encNumber = encrypt(
              account_number?.toString() || '',
              process.env.REACT_APP_CRYPT_ACCOUNT_NUMBER || '',
              process.env.REACT_APP_CRYPT_SALT || '',
              process.env.REACT_APP_CRYPT_IV || '',
            );
            const maskNumber = masking(account_number?.toString() || '', 2);

            const api = new ContractAPI<null>({
              path: CONTRACTORS_API_PATH.BANK,
              params: {
                member_id,
                contract_id,
                bank_name: navigateState.bank_name,
                branch_name: navigateState.branch_name,
                deposit_code: navigateState.deposit_code,
                account_holder: navigateState.account_holder,
                account_number: encNumber,
                account_number_mask: maskNumber,
              },
              member_id,
            });
            if (await api.put()) {
              setIsRequestBank(true);
            }
          })();
        }
      }
    }
  }, [isInit]);

  /**
   * メンテ請求のリスクエストタイミングの監視
   * ・画像をs3ファイルに保存してパスを受け取ること
   * ・口座情報の更新が終わっていること
   */
  useEffect(() => {
    if (isUpload && isRequestBank) {
      setIsStartrequest(true);
    }
  }, [isUpload, isRequestBank]);

  /**
   * メンテ請求
   */
  useEffect(() => {
    if (isStratRequest) {
      (async () => {
        const api = new ContractAPI<PostApiResponse>({
          path: CONTRACTORS_API_PATH.MAINT_REQUEST,
          params: {
            member_id,
            contract_id,
            item_ids,
            receipt_file_paths,
            use_date: navigateState.use_date,
          },
          member_id,
        });
        const result = await api.post();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            navigate('/maint/result', {
              state: {
                request_id: response.request_id,
              },
            });
          }
        } else if (result.statusCode === 401) {
          setExpired(true);
        } else {
          navigate('/maint/input', {
            state: { code: RESULT_CODE.MAINT_REQUEST_CREATE_FAILED },
          });
        }
      })();
    }
  }, [isStratRequest]);

  return (
    <PrivateSite title="処理中" loading={true} expired={expired}>
      <Container>
        <Row>
          <Col>
            <RequestStep step={2} />
          </Col>
        </Row>
      </Container>
    </PrivateSite>
  );
};
