import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { PrivateSite } from 'ui/template/PrivateSite';
import { Banner } from 'ui/organism/Banner';
import { useCookies } from 'react-cookie';
import { CampaignView } from 'ui/organism/Campaign';
import { NoticeView } from 'ui/organism/Notice';
import { Amount } from 'ui/molecule/Amount';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import invoice from 'contents/images/icon/invoice.svg';
import yen from 'contents/images/icon/yen.svg';
import usage from 'contents/images/icon/usage.svg';
import './style.scss';
import { COST_TYPE } from 'core/const/CostType';
import { GetApiResponse } from 'core/model/contractors-api/BillingNext';
import { DISTRIBUTOR } from 'core/const/Distributor';
import { CONTRACT_TYPE } from 'core/const/ContractType';
import { JUDGEMENT } from 'core/const/Judgement';
import { MANUAL_NUMBER } from 'core/const/ManualNumber';
import { CONTRACT_STATUS } from 'core/const/ContractStatus';

type NavigateState = {
  code?: string;
};

export const Home = () => {
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [member_mame, setMemberName] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [cost_type, setCostType] = useState<number>();
  const [distributor, setDistributor] = useState<number>();
  const [contract_type, setContractType] = useState<number>();
  const [judgement, setJudgement] = useState<number>();
  const [sales_scheme_id, setSalesSchemeId] = useState<number>();
  const [status, setStatus] = useState<number>();
  const [billingNext, setBillNext] = useState<GetApiResponse>();
  const [expired, setExpired] = useState<boolean>(false);
  const [guidance_url, setGuidanceUrl] = useState<string>();
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const carmoThreeSalesSchemeId = 9;

  /**
   * ログイン情報を取得
   */
  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
      setMemberName(cookieContract.member_name);
      setContractId(cookieContract.contract_id);
      setCostType(cookieContract.cost_type);
      setDistributor(cookieContract.distributor);
      setContractType(cookieContract.contract_type);
      setJudgement(cookieContract.judgement);
      setSalesSchemeId(cookieContract.sales_scheme_id);
      setStatus(cookieContract.status);
    }
  }, []);

  /**
   * 請求情報取得
   */
  useEffect(() => {
    if (member_id && status) {
      if (status === CONTRACT_STATUS.ONGOING) {
        (async () => {
          const api = new ContractAPI<GetApiResponse>({
            path: CONTRACTORS_API_PATH.BILLING_NEXT,
            params: {
              member_id,
              contract_id: contract_id,
            },
            member_id,
          });
          const result = await api.get();
          if (result.success && result.statusCode === 200) {
            const response = api.getResponse();
            if (response) {
              setBillNext(response);
            }
          } else if (result.statusCode === 401) {
            setExpired(true);
          }
        })();
      } else {
        setBillNext(undefined);
      }
    }
  }, [member_id, status]);

  /**
   * 契約内容によってリンクを設定
   */
  useEffect(() => {
    let manualPath = '';

    if (
      contract_type === CONTRACT_TYPE.NEW_CAR &&
      sales_scheme_id === carmoThreeSalesSchemeId
    ) {
      manualPath = MANUAL_NUMBER.CARMO_THREE;
    } else if (
      contract_type === CONTRACT_TYPE.NEW_CAR ||
      contract_type === CONTRACT_TYPE.UNUSED_CAR
    ) {
      manualPath =
        cost_type === COST_TYPE.NO_ADVANCE
          ? MANUAL_NUMBER.NEWCAR_NO_ADVANCE
          : MANUAL_NUMBER.NEWCAR;
    } else if (contract_type === CONTRACT_TYPE.USED_CAR) {
      switch (judgement) {
        case JUDGEMENT.OAC:
          manualPath =
            cost_type === COST_TYPE.NO_ADVANCE
              ? MANUAL_NUMBER.OAC_USEDCAR_NO_ADVANCE
              : MANUAL_NUMBER.OAC_USEDCAR;
          break;
        case JUDGEMENT.APLUS:
          manualPath =
            cost_type === COST_TYPE.NO_ADVANCE
              ? MANUAL_NUMBER.APLUS_USEDCAR_NO_ADVANCE
              : MANUAL_NUMBER.APLUS_USEDCAR;
          break;
        default:
          manualPath = '';
          break;
      }
    }

    if (manualPath) {
      setGuidanceUrl(
        `https://app.carmo-kun.jp/contents/manual/${manualPath}/index`,
      );
    }
  }, [contract_type, cost_type, judgement, sales_scheme_id]);

  return (
    <PrivateSite code={navigateState?.code} expired={expired}>
      <Container className="home-invoice">
        <Row>
          <Col>
            <div className="home-invoice__username">
              <span>{member_mame}</span> 様
            </div>

            <div className="home-invoice__transfer">
              <Container>
                {distributor === DISTRIBUTOR.OAC ? (
                  <>
                    <Row>
                      <Col xs={6}>
                        <p>次回振替金額(予定)</p>
                      </Col>
                      <Col>
                        <p>
                          <Amount amount={billingNext?.amount} />
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={6}>
                        <p>次回振替日</p>
                      </Col>
                      <Col>
                        <p>{billingNext?.billing_date || '---'}</p>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row>
                      <Col xs={6}>
                        <p>毎月の請求金額</p>
                      </Col>
                      <Col>
                        <p>
                          <Amount amount={billingNext?.amount} />
                        </p>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </div>
          </Col>
        </Row>

        {distributor === DISTRIBUTOR.OAC && (
          <Row className="home-invoice__status">
            <Col>
              <Link to="/billing">
                <p>ご請求お支払い状況はこちら</p>
              </Link>
            </Col>
          </Row>
        )}
      </Container>

      <Container className="home-maintenance">
        {cost_type === COST_TYPE.ADVANCE && (
          <>
            <Row className="mb-3">
              <Col className="home-maintenance__block">
                <Link to="/maint/input">
                  <p>
                    メンテナンス
                    <br />
                    費用請求
                  </p>
                  <img src={invoice} alt="請求書" />
                  <img src={yen} alt="¥" />
                </Link>
              </Col>
              <Col className="home-maintenance__block">
                <Link to="/maint">
                  <p>
                    メンテナンス
                    <br />
                    請求状況
                  </p>
                  <img src={usage} alt="ご利用明細" />
                </Link>
              </Col>
            </Row>

            <div className="home-maintenance__link">
              <Link to="/merchant">メンテナンスプラン対応業者一覧</Link>
            </div>
          </>
        )}

        <div className="home-maintenance__link">
          <Link to="/contract">お客様情報の確認</Link>
        </div>

        <Row className="mb-3">
          <Col className="home-maintenance__block">
            <a href={guidance_url} target="_blank">
              <p>
                ご利用の
                <br />
                手引き
              </p>
            </a>
          </Col>
          <Col className="home-maintenance__block">
            <Link to="/benefit">
              <p>
                ご契約者様向け
                <br />
                特典一覧
              </p>
            </Link>
          </Col>
        </Row>
      </Container>

      <Banner />
      <CampaignView />
      <NoticeView />
    </PrivateSite>
  );
};
