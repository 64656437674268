import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ContractAmount } from 'core/model/contract/ContractAmount';
import { Amount } from 'ui/molecule/Amount';
import { REVENUE_TYPE } from 'core/const/RevenueType';

interface Props {
  amounts: ContractAmount[];
}

/**
 * 料金内訳
 */
export const AmountSummary: React.FC<Props> = ({
  amounts,
}): React.ReactElement => {
  const [lease_amount, setLeaseAmount] = useState<number>(0);
  const [support_amount, setSupportAmount] = useState<number>(0);
  const [maint_amount, setMaintAmount] = useState<number>(0);
  const [warranty_amount, setWarrantyAmount] = useState<number>(0);
  const [etc_amount, setEtcAmount] = useState<number>(0);

  useEffect(() => {
    let lease_amount = 0;
    let support_amount = 0;
    let maint_amount = 0;
    let warranty_amount = 0;
    let etc_amount = 0;
    amounts.forEach((row) => {
      const amount = Number(row.amount);
      switch (row.revenue_type) {
        case REVENUE_TYPE.LEASE:
          lease_amount += amount;
          break;
        case REVENUE_TYPE.BROKERAGE_FEE:
        case REVENUE_TYPE.CUSTOMER_SUPPORT_FEE:
          support_amount += amount;
          break;
        case REVENUE_TYPE.MAINTENANCE_PLAN:
          maint_amount += amount;
          break;
        case REVENUE_TYPE.WARRANTY:
          warranty_amount += amount;
          break;
        default:
          etc_amount += amount;
          break;
      }
    });
    setLeaseAmount(lease_amount);
    setSupportAmount(support_amount);
    setMaintAmount(maint_amount);
    setWarrantyAmount(warranty_amount);
    setEtcAmount(etc_amount);
  });

  return (
    <Container>
      <Row>
        <Col>
          <dl>
            <dt>内訳</dt>
            <dd>
              {lease_amount > 0 && (
                <Row>
                  <Col xs={8}>
                    <p className="list">リース料金</p>
                  </Col>
                  <Col>
                    <p className="text-end">
                      <Amount amount={lease_amount} />
                    </p>
                  </Col>
                </Row>
              )}

              {support_amount > 0 && (
                <Row>
                  <Col xs={8}>
                    <p className="list">カーライフサポート料金</p>
                  </Col>
                  <Col>
                    <p className="text-end">
                      <Amount amount={support_amount} />
                    </p>
                  </Col>
                </Row>
              )}

              {maint_amount > 0 && (
                <Row>
                  <Col xs={8}>
                    <p className="list">メンテナンスプラン料金</p>
                  </Col>
                  <Col>
                    <p className="text-end">
                      <Amount amount={maint_amount} />
                    </p>
                  </Col>
                </Row>
              )}

              {warranty_amount > 0 && (
                <Row>
                  <Col xs={8}>
                    <p className="list">カルモ保証プラス料金</p>
                  </Col>
                  <Col>
                    <p className="text-end">
                      <Amount amount={warranty_amount} />
                    </p>
                  </Col>
                </Row>
              )}

              {etc_amount > 0 && (
                <Row>
                  <Col xs={8}>
                    <p className="list">その他料金</p>
                  </Col>
                  <Col>
                    <p className="text-end">
                      <Amount amount={etc_amount} />
                    </p>
                  </Col>
                </Row>
              )}
            </dd>
          </dl>
        </Col>
      </Row>
    </Container>
  );
};
