import React, { useEffect, useState } from 'react';
import { NOTICE_TAG } from 'core/const/NoticeTag';
import './style.scss';

interface Props {
  tag: number;
}

/**
 * お知らせタグ
 */
export const NoticeTag: React.FC<Props> = ({
  tag,
}: Props): React.ReactElement => {
  const [text, setText] = useState<string>();
  const [style, setStyle] = useState<string>();

  useEffect(() => {
    switch (tag) {
      case NOTICE_TAG.IMPORTANT:
        setText('重要');
        setStyle('important');
        break;
      case NOTICE_TAG.PROFIT:
        setText('お得');
        setStyle('profit');
        break;
      case NOTICE_TAG.CONFIRM:
        setText('確認');
        setStyle('confirm');
        break;
    }
  }, []);
  return (
    <>
      {tag !== NOTICE_TAG.NONE && (
        <span className={`notice-tag ${style}`}>{text}</span>
      )}
    </>
  );
};
