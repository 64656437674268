/* eslint-disable @typescript-eslint/no-explicit-any */
import { format } from 'date-fns';
import { decrypt, encrypt } from './Crypt';

export type CookieContract = {
  member_id: string;
  contract_id: string;
  distributor: number;
  lease_date: string;
  lease_amount: number;
  contract_months: number;
  member_name: string;
  cost_type: number;
  contract_type: number;
  judgement: number;
  is_multiple: boolean;
  sales_scheme_id: number;
  status: number;
};

/**
 * COOKIE用の契約情報を暗号化する
 * @param contract
 * @returns 暗号化された契約情報
 */
export const encCookieContract = (contract: CookieContract) => {
  const time = format(new Date(), 'yyyyMMddHHmmss');
  const user = [
    contract.member_id,
    contract.contract_id,
    contract.distributor,
    contract.lease_date,
    contract.lease_amount,
    contract.contract_months,
    contract.member_name,
    contract.cost_type,
    contract.contract_type,
    contract.judgement,
    contract.is_multiple,
    contract.sales_scheme_id,
    contract.status,
    time,
  ];
  return encrypt(
    user.join(','),
    process.env.REACT_APP_CRYPT_COOKIE || '',
    process.env.REACT_APP_CRYPT_SALT || '',
    process.env.REACT_APP_CRYPT_IV || '',
  );
};

/**
 * COOKIE用の契約情報を復号する
 * @param contract
 * @returns 契約情報のオブジェクト
 */
export const decCookieContract = (enc: string): CookieContract | null => {
  if (!enc) {
    return null;
  }
  const dec = decrypt(
    enc,
    process.env.REACT_APP_CRYPT_COOKIE || '',
    process.env.REACT_APP_CRYPT_SALT || '',
    process.env.REACT_APP_CRYPT_IV || '',
  );
  const decArray = dec.split(',');
  if (decArray.length !== 14) {
    return null;
  }
  return {
    member_id: decArray[0],
    contract_id: decArray[1],
    distributor: Number(decArray[2]),
    lease_date: decArray[3],
    lease_amount: Number(decArray[4]),
    contract_months: Number(decArray[5]),
    member_name: decArray[6],
    cost_type: Number(decArray[7]),
    contract_type: Number(decArray[8]),
    judgement: Number(decArray[9]),
    is_multiple: decArray[10] === 'true',
    sales_scheme_id: Number(decArray[11]),
    status: Number(decArray[12]),
  };
};
