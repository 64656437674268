import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { MaintHistory } from 'core/model/contract/MaintHistory';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import './style.scss';

export type HistoryViewState = {
  from: string;
  to: string;
  month: number;
};

interface Props {
  state: HistoryViewState;
}

export const MaintHistoryView: React.FC<Props> = ({
  state,
}: Props): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [histories, setHistories] = useState<MaintHistory[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  const on_click = async () => {
    if (!loaded) {
      const api = new ContractAPI<MaintHistory[]>({
        path: CONTRACTORS_API_PATH.MAINT_HISTORY,
        params: {
          member_id,
          contract_id,
          date_from: state.from,
          date_to: state.to,
        },
        member_id,
      });

      if (await api.get()) {
        const res = api.getResponse();
        if (res) {
          setHistories(res);
        }
      }

      setLoaded(true);
    }
  };

  return (
    <Row className="maint-history">
      <Col>
        <Accordion>
          <Accordion.Item eventKey={`ac-${state.month}`}>
            <Accordion.Header onClick={on_click}>
              <div className="month">
                <p>{state.month}ヶ月</p>
                <p>
                  {state.from}〜{state.to}
                </p>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {!loaded ? (
                <PartLoadingView />
              ) : (
                <>
                  {histories.length > 0 ? (
                    <div className="maint-history__table">
                      <Table>
                        <thead>
                          <tr>
                            <th>メンテ実施日</th>
                            <th>振込日</th>
                            <th>詳細</th>
                          </tr>
                        </thead>
                        <tbody>
                          {histories.map((history, index) => {
                            return (
                              <tr
                                key={`maint-history-m${state.month}-${index}`}
                              >
                                <td>{history.use_date}</td>
                                <td>{history.transfer_date}</td>
                                <td>
                                  <Link
                                    to={`/maint/history/detail/${history.maint_history_id}`}
                                  >
                                    確認
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div className="not-found">
                      この期間での利用履歴はありません。
                    </div>
                  )}
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};
