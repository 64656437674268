import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { PrivateSite } from 'ui/template/PrivateSite';
import { decrypt, encrypt } from 'compornents/functions/util/Crypt';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { PutApiResponse } from 'core/model/contractors-api/APIResult';
import { format } from 'date-fns';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { RESULT_CODE } from 'core/const/ResultCode';

type NavigateState = {
  member_id: string;
  auth: string;
  new_password: string;
  referrer?: string;
};

export const InitPasswordSettingExecute: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    console.log(navigateState);
    (async () => {
      const auth_array = decrypt(
        navigateState.auth,
        process.env.REACT_APP_CRYPT_API || '',
        process.env.REACT_APP_CRYPT_SALT || '',
        process.env.REACT_APP_CRYPT_IV || '',
      ).split(',');
      if (auth_array.length !== 3) {
        navigate('/home');
        return;
      }

      const auth = [
        auth_array[1],
        navigateState.new_password,
        format(new Date(), 'yyyyMMddHHmmss'),
      ];
      const api = new ContractAPI<PutApiResponse>({
        path: CONTRACTORS_API_PATH.MEMBER_SESSION,
        params: {
          auth: encrypt(
            auth.join(','),
            process.env.REACT_APP_CRYPT_API || '',
            process.env.REACT_APP_CRYPT_SALT || '',
            process.env.REACT_APP_CRYPT_IV || '',
          ),
        },
        member_id: navigateState.member_id,
      });
      const result = await api.put();
      if (result.success && result.statusCode === 200) {
        if (navigateState?.referrer) {
          navigate(navigateState.referrer);
        } else {
          navigate('/home', {
            state: { code: RESULT_CODE.PASSWORD_CHANGED },
          });
        }
      } else if (result.statusCode === 401) {
        setExpired(true);
      } else {
        if (result.code === 'E003') {
          navigate('/password/init', {
            state: {
              code: RESULT_CODE.UNMATCH_PASSWORD,
              member_id: navigateState.member_id,
              auth: navigateState.auth,
              referrer: navigateState?.referrer,
            },
          });
        } else if (result.code === 'E004') {
          navigate('/password/init', {
            state: {
              code: RESULT_CODE.SAME_PASSWORD,
              member_id: navigateState.member_id,
              auth: navigateState.auth,
              referrer: navigateState?.referrer,
            },
          });
        } else {
          navigate('/home', {
            state: { code: RESULT_CODE.PASSWORD_CHANGE_FAILED },
          });
        }
      }
    })();
  }, []);

  return (
    <PrivateSite title="処理中" loading={true} expired={expired}>
      <Container>
        <Row>
          <Col>
            <p>パスワードの初期設定を行なっています。</p>
          </Col>
        </Row>
      </Container>
    </PrivateSite>
  );
};
