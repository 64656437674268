import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { addDays, addYears, format, isAfter } from 'date-fns';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { PrivateSite } from 'ui/template/PrivateSite';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { BillingView, BillingViewState } from 'ui/organism/Billing';

export const BillingConfirm = () => {
  const [cookies] = useCookies(['contract']);
  const [lease_date, setLeaseDate] = useState<string>('');
  const [years, setYears] = useState<number>();
  const [viewStates, setViewState] = useState<BillingViewState[]>();

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setLeaseDate(cookieContract.lease_date);
      setYears(cookieContract.contract_months / 12);
    }
  }, []);

  useEffect(() => {
    if (years) {
      const now = new Date();
      const tmp: BillingViewState[] = [];
      for (let i = 0; i < years; i = i + 1) {
        const from = addYears(new Date(lease_date), i);
        const to = addDays(addYears(from, 1), -1);

        // ※fromが現在日時を超えた場合のみ設定
        if (isAfter(now, from)) {
          tmp.unshift({
            from: format(from, 'yyyy/MM/dd'),
            to: format(to, 'yyyy/MM/dd'),
            count_from: i * 12 + 1,
            count_to: (i + 1) * 12,
            month: (i + 1) * 12,
          });
        }
      }
      setViewState(tmp);
    }
  }, [years]);

  return (
    <PrivateSite title="ご請求お支払い状況">
      <Container>
        <Row>
          <Col>毎月のお支払い料金をご確認いただけます。</Col>
        </Row>

        <Row>
          <Col>
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <p>入金状況について</p>
                </Accordion.Header>

                <Accordion.Body>
                  <dl>
                    <dt>確認中</dt>
                    <dd>口座振替後、銀行からの振替結果連絡待ち</dd>
                    <dt>カルモ分済</dt>
                    <dd>
                      カーライフサポート料のお支払い完了
                      <br />
                      <span>
                        ※この場合、オリックス自動車分のお支払い状況は確認できません。オリックス自動車分のお支払い状況を確認したい場合はオリックス自動車へお問合せください。
                      </span>
                    </dd>
                    <dt>済</dt>
                    <dd>
                      オリックス自動車へのお車のリース料金、定額カルモくんへのカーライフサポート料ともにお支払い完了
                    </dd>
                    <dt>予定</dt>
                    <dd>今後、お支払い予定のご請求</dd>
                  </dl>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="headline">ご請求お支払い状況</div>
          </Col>
        </Row>

        {!viewStates ? (
          <PartLoadingView />
        ) : (
          <>
            {viewStates.map((viewState, index) => {
              return (
                <BillingView state={viewState} key={`billing-view-${index}`} />
              );
            })}
          </>
        )}
      </Container>
    </PrivateSite>
  );
};
