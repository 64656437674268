import React, { useEffect, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { PublicSite } from 'ui/template/PublicSite';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { encrypt } from 'compornents/functions/util/Crypt';
import { addDays, format } from 'date-fns';
import {
  decCookieContract,
  encCookieContract,
} from 'compornents/functions/util/Cookie';
import { PutApiResponse } from 'core/model/contractors-api/BackdoorLogin';
import { RESULT_CODE } from 'core/const/ResultCode';
import { isValidContract } from 'compornents/functions/contract/ValidContract';

type NavigateState = {
  code?: string;
};

export const BackdoorLogin: React.FC = () => {
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const navigate = useNavigate();
  const [cookie, setCookie, removeCookie] = useCookies(['contract']);
  const [code, setCode] = useState<string | undefined>(navigateState?.code);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const member_id = searchParams.get('member_id');

  useEffect(() => {
    if (cookie.contract) {
      const cookieContract = decCookieContract(cookie.contract);
      if (cookieContract) {
        navigate('/home');
      } else {
        removeCookie('contract');
      }
    }
  }, []);

  const onSubmit = async (formInputs: {}) => {
    setLoading(true);
    const params = JSON.parse(JSON.stringify(formInputs));
    const auth_array = [params.member_id, format(new Date(), 'yyyyMMddHHmmss')];
    const auth = encrypt(
      auth_array.join(','),
      process.env.REACT_APP_CRYPT_API || '',
      process.env.REACT_APP_CRYPT_SALT || '',
      process.env.REACT_APP_CRYPT_IV || '',
    );

    const api = new ContractAPI<PutApiResponse>({
      path: CONTRACTORS_API_PATH.AUTH_BACKDOOR_LOGIN,
      params: { auth },
    });
    const result = await api.put();
    if (result.success && result.statusCode === 200) {
      const response = api.getResponse();
      if (response) {
        // 有効な契約を抽出
        const valid_contracts = response.contracts.filter((contract) => {
          return isValidContract(contract);
        });

        // 有効契約の件数に応じて画面遷移
        //   ・1件 - HOME画面に遷移
        //   ・2件以上 - 契約選択画面に遷移
        //   ・それ以外 - ログイン画面でエラー出力
        if (valid_contracts.length === 1) {
          const contract = valid_contracts[0];
          const enc = encCookieContract({
            member_id: response.member_id,
            contract_id: contract.contract_id,
            distributor: contract.distributor,
            lease_date: contract.lease_date_from,
            lease_amount: contract.lease_amount,
            contract_months: contract.contract_months,
            member_name: contract.member_name,
            cost_type: contract.cost_type,
            contract_type: contract.contract_type,
            judgement: contract.judgement,
            is_multiple: false,
            sales_scheme_id: contract.sales_scheme_id,
            status: contract.status,
          });
          removeCookie('contract');
          setCookie('contract', enc, { expires: addDays(new Date(), 1) });
          navigate('/home');
        } else if (valid_contracts.length >= 2) {
          navigate('/login/select', {
            state: { member_id: response.member_id },
          });
        } else {
          setCode(RESULT_CODE.CONTRACT_NOT_FOUND);
        }
      }
    } else {
      setCode(RESULT_CODE.BACKDOOR_LOGIN_MISTAKE);
    }
    setLoading(false);
  };

  const validationSchema = yup.object().shape({
    member_id: yup.string().required('必須項目です。'),
  });

  const formik = useFormik({
    initialValues: {
      member_id,
    },
    onSubmit,
    validationSchema,
  });

  return (
    <PublicSite title="ログイン" code={code} loading={loading}>
      <Form noValidate onSubmit={formik.handleSubmit}>
        <Container>
          <Row className="row__form">
            <Col>
              <Form.Group className="form-group" controlId="email">
                <Form.Label>会員ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="MID_xxx"
                  isInvalid={
                    !!formik.touched.member_id && !!formik.errors.member_id
                  }
                  {...formik.getFieldProps('member_id')}
                />
                {formik.touched.member_id && formik.errors.member_id ? (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.member_id}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </Col>
          </Row>

          <Row className="row__submit-button">
            <Col>
              <Button type="submit">ログイン</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </PublicSite>
  );
};
