import React, { useEffect, useState } from 'react';
import { DEPOSIT_CODE } from 'core/const/DepositCode';

interface Props {
  code: number;
}

export const DepositCode: React.FC<Props> = ({
  code,
}: Props): React.ReactElement => {
  const [text, setText] = useState<string>('---');
  useEffect(() => {
    switch (code) {
      case DEPOSIT_CODE.ORDINARY:
        setText('普通');
        break;
      case DEPOSIT_CODE.CURRENT:
        setText('当座');
        break;
      case DEPOSIT_CODE.SAVINGS:
        setText('貯蓄');
        break;
      case DEPOSIT_CODE.ETC:
        setText('その他');
        break;
      default:
        setText('その他');
        break;
    }
  });
  return <>{text}</>;
};
