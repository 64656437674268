import { addDays } from 'date-fns';
import { CONTRACT_STATUS } from 'core/const/ContractStatus';
import { Contract } from 'core/model/contract/Contract';

/**
 * Webアプリへのアクセスが可能な契約かチェック
 */
export const isValidContract = (contract: Contract): boolean => {
  if (contract.status === CONTRACT_STATUS.ONGOING) {
    // 契約中は有効
    return true;
  } else {
    const current_date = new Date();

    // リース終了日から30日までは有効
    if (contract.lease_end_date) {
      const expired = addDays(new Date(contract.lease_end_date), 30);
      if (current_date <= expired) {
        return true;
      }
    }

    // 解約日から30日までは有効
    if (contract.cancel_date) {
      const expired = addDays(new Date(contract.cancel_date), 30);
      if (current_date <= expired) {
        return true;
      }
    }
  }

  return false;
};
