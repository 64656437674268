import React, { useEffect, useState } from 'react';
import { Col, Container, ListGroup, Row } from 'react-bootstrap';
import { PrivateSite } from 'ui/template/PrivateSite';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { useCookies } from 'react-cookie';
import { MaintMerchant } from 'core/model/contract/MaintMerchant';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import './style.scss';

export const MaintMerchantPage: React.FC = (): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [dealers, setDealers] = useState<MaintMerchant[]>();
  const [stores, setStores] = useState<MaintMerchant[]>();
  const [gasStations, setGasStations] = useState<MaintMerchant[]>();
  const [member_id, setMemberId] = useState<string>();
  const [expired, setExpired] = useState<boolean>(false);
  const service_url = process.env.REACT_APP_SERVICE_URL || '';

  const categorys = [
    {
      value: 2,
      headline: 'カー用品店',
    },
    {
      value: 3,
      headline: 'ガソリンスタンド・整備工場',
    },
    {
      value: 1,
      headline: 'ディーラー',
    },
  ];

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      categorys.forEach(async (category) => {
        const response = await request(category.value);
        if (response) {
          if (category.value === 1) {
            setDealers(response);
          } else if (category.value === 2) {
            setStores(response);
          } else if (category.value === 3) {
            setGasStations(response);
          }
        }
      });
    }
  }, [member_id]);

  const request = async (
    category: number,
  ): Promise<MaintMerchant[] | null | undefined> => {
    const api = new ContractAPI<MaintMerchant[]>({
      path: CONTRACTORS_API_PATH.MAINT_MERCAHNT,
      params: { category },
      member_id,
    });
    const result = await api.get();
    if (result.success && result.statusCode === 200) {
      const response = api.getResponse();
      return response;
    } else if (result.statusCode === 401) {
      setExpired(true);
    }
    return null;
  };

  const getMerchant = (category: number) => {
    if (category === 1) {
      return dealers;
    } else if (category === 2) {
      return stores;
    } else if (category === 3) {
      return gasStations;
    }
    return;
  };

  return (
    <PrivateSite title="メンテナンスプラン対応業者" expired={expired}>
      <Container>
        <Row className="row__page-description">
          <Col>
            <p>
              下記一覧に記載されている業者を推奨しております。
              <br />
              なお、各店舗と定額カルモくんは直接提携しておりません。各店舗にお問合せ後、メンテナンスを実施していただき、「点検実施日・点検項目・金額内訳」が分かる納品明細書の画像を申請時に添付してください。
              <br />
              ※記載のない業者でも「点検実施日・点検項目・金額内訳」が印字されている明細書をご提出可能な業者でしたら問題ございません。
            </p>
          </Col>
        </Row>

        {/*
        <Row>
          <Col>
            <div className="annotation">
              下記リストに記載のない業者をご希望の場合、
              <Link to="" className="font-color-link">
                こちら
              </Link>
              からお問い合わせください。
            </div>
          </Col>
        </Row>
        */}

        {categorys.map((category) => {
          return (
            <div key={`merchant-category-${category.value}`}>
              <Row>
                <Col>
                  <div className="headline">{category.headline}</div>
                </Col>
              </Row>

              {getMerchant(category.value) ? (
                <Row>
                  <Col className="row-merchant">
                    <ListGroup>
                      {getMerchant(category.value)?.map((row, index) => {
                        return (
                          <ListGroup.Item
                            key={`merchant-${category.value}-${index}`}
                          >
                            <img
                              src={`${service_url}/${row.image_path}`}
                              alt={row.merchant_name}
                            />
                            <p>{row.merchant_name}</p>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </Col>
                </Row>
              ) : (
                <PartLoadingView />
              )}
            </div>
          );
        })}
      </Container>
    </PrivateSite>
  );
};
