import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { useFormik } from 'formik';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useState } from 'react';
import { RESULT_CODE } from 'core/const/ResultCode';
import { PublicSite } from 'ui/template/PublicSite';

export const PasswordReset: React.FC = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (formInputs: {}) => {
    setLoading(true);
    const params = JSON.parse(JSON.stringify(formInputs));

    const api = new ContractAPI({
      path: CONTRACTORS_API_PATH.MEMBER_SESSION_RESET,
      params: { email: params.email },
    });
    const result = await api.post();
    if (result.success) {
      navigate('/reset/result');
    } else {
      setCode(RESULT_CODE.RESET_MAIL_SEND_FAILED);
    }

    setLoading(false);
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('メールアドレスを入力してください。')
      .required('必須項目です。'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <PublicSite title="パスワードリセット" code={code} loading={loading}>
      <Container>
        <Row className="row__page-description">
          <Col>
            <p>
              パスワードをリセットするためのURLを記載したメールをお送りいたします。
            </p>
            <p>
              "@app.carmo-kun.jp"からのメールを受け取れるよう設定をお願いいたします。
            </p>
            <p className="font-color-carmo-red">
              ※パスワードリセット用URLの有効期限は24時間となります
            </p>
          </Col>
        </Row>
      </Container>

      <Form noValidate onSubmit={formik.handleSubmit}>
        <Container>
          <Row className="row__form">
            <Col>
              <Form.Group className="form-group" controlId="email">
                <Form.Label>ご登録メールアドレス</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="abcd@carmo-kun.jp"
                  isInvalid={!!formik.touched.email && !!formik.errors.email}
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row className="row__submit-button">
            <Col>
              <Button type="submit">送信</Button>
            </Col>
          </Row>
        </Container>
      </Form>
    </PublicSite>
  );
};
