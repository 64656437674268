import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, ListGroup, Modal, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { PrivateSite } from 'ui/template/PrivateSite';
import { RequestStep } from 'ui/organism/RequestStep';
import { ContractBankView } from 'ui/organism/ContractBank';
import { DateFormmat } from 'ui/molecule/DateFormat';
import { DISTRIBUTOR } from 'core/const/Distributor';
import './style.scss';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { ContractMaintItem } from 'core/model/contract/ContractMaintItem';

type NavigateState = {
  item_ids: string[];
  receiptFiles: File[];
  use_date: string;
  is_registered_bank: boolean;
  bank_name?: string;
  branch_name?: string;
  deposit_code?: number;
  account_holder?: string;
  account_number?: string;
};

type ReceiptModal = {
  isShow: boolean;
  receipt: File | null;
};

export const MaintRequestCreateConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const [cookies] = useCookies(['contract']);
  const [receiptModal, setReceiptModal] = useState<ReceiptModal>({
    isShow: false,
    receipt: null,
  });
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [distributor, setDistributor] = useState<number>();
  const [items, setItems] = useState<ContractMaintItem[]>([]);

  /**
   * 画面間のパラメータをロード
   */
  const item_ids = navigateState.item_ids;
  const receiptFiles = navigateState.receiptFiles;
  const use_date = navigateState.use_date;
  const is_registered_bank = navigateState.is_registered_bank;
  const bank_name = navigateState.bank_name;
  const branch_name = navigateState.branch_name;
  const deposit_code = navigateState.deposit_code;
  const account_holder = navigateState.account_holder;
  const account_number = navigateState.account_number;

  /**
   * ログイン情報を取得
   */
  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
      setDistributor(cookieInfo.distributor);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        // 選択したメンテナンス情報を取得
        const api = new ContractAPI<ContractMaintItem[]>({
          path: CONTRACTORS_API_PATH.CONTRACT_MAINT,
          params: {
            member_id,
            contract_id,
            use_date,
          },
          member_id,
        });
        if (await api.get()) {
          const response = api.getResponse();
          if (response) {
            const selected = response.filter((row) => {
              return item_ids.some(
                (id) => id === String(row.contract_maint_item_id),
              );
            });
            setItems(selected);
          }
        }
      })();
    }
  }, [member_id]);

  const on_submit = () => {
    navigate('/maint/execute', {
      state: {
        item_ids,
        receiptFiles,
        use_date,
        is_registered_bank,
        bank_name,
        branch_name,
        deposit_code,
        account_holder,
        account_number,
      },
    });
  };

  const on_back = () => {
    navigate('/maint/input', {
      state: {
        item_ids,
        receiptFiles,
        use_date,
        is_registered_bank,
        bank_name,
        branch_name,
        deposit_code,
        account_holder,
        account_number,
      },
    });
  };

  const backward_state = {
    item_ids,
    receiptFiles,
    use_date,
    is_registered_bank,
    bank_name,
    branch_name,
    deposit_code,
    account_holder,
    account_number,
  };

  return (
    <>
      <PrivateSite
        title="メンテナンス申請・確認"
        backward_state={backward_state}
      >
        <Container>
          <Row>
            <Col>
              <RequestStep step={2} />
            </Col>
          </Row>
        </Container>

        <Container className="mb-3">
          <Row>
            <Col>
              以下の内容で請求を確定しますか？
              <br />
              請求内容に不足や漏れがあると、解消するまで振込できませんのでよくご確認の上、修正する場合は、戻るボタンから入力画面に戻ってください。
            </Col>
          </Row>
        </Container>

        <Container>
          <Row className="maint-confirm-column__headline">
            <Col>
              <p>メンテナンス実施日</p>
            </Col>
          </Row>

          <Row className="maint-confirm-column__value">
            <Col>
              <div className="maint-confirm-column__usedate">
                <DateFormmat date={use_date} />
              </div>
            </Col>
          </Row>

          <Row className="maint-confirm-column__headline">
            <Col>
              <p>明細写真</p>
            </Col>
          </Row>

          <Row className="maint-confirm-column__value">
            <Col>
              <Row
                xs={2}
                sm={2}
                md={2}
                className="maint-confirm-column__receipt"
              >
                {receiptFiles.map((row, index) => {
                  return (
                    <Col key={`receipt-${index}`} className="mb-2">
                      <label>
                        <img
                          src={URL.createObjectURL(row)}
                          alt={`receipt-${index}`}
                        />
                        <Button
                          onClick={() =>
                            setReceiptModal({ isShow: true, receipt: row })
                          }
                        />
                      </label>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>

          <Row className="maint-confirm-column__headline">
            <Col>
              <p>メンテ実施項目</p>
            </Col>
          </Row>

          <Row className="maint-confirm-column__value">
            <Col>
              <ListGroup className="maint-confirm-column__item">
                <ListGroup.Item className="title">項目</ListGroup.Item>
                {items.length > 0 ? (
                  <>
                    {items.map((row, index) => {
                      return (
                        <ListGroup.Item
                          className="row-item"
                          key={`maint-item-${index}`}
                        >
                          <p>{row.item_name}</p>
                          <p className="max-amount">
                            上限金額：{
                            row.max_amount_valid_flg === 1
                                ? `¥${row.max_amount.toLocaleString()} まで`
                                : 'なし'
                          }
                          </p>
                        </ListGroup.Item>
                      );
                    })}
                  </>
                ) : (
                  <PartLoadingView />
                )}
              </ListGroup>
            </Col>
          </Row>
        </Container>

        {/** 振込口座情報を表示 */}
        {distributor === DISTRIBUTOR.OAC ? (
          <ContractBankView />
        ) : (
          <>
            {is_registered_bank ? (
              <ContractBankView />
            ) : (
              <ContractBankView
                bank_props={{
                  bank_name: bank_name || '',
                  branch_name: branch_name || '',
                  deposit_code: deposit_code || 1,
                  account_holder: account_holder || '',
                  account_number_mask: account_number || '',
                }}
              />
            )}
          </>
        )}

        <Container>
          <Row className="maint-confirm__link">
            <Col>
              <a onClick={on_back} className="backward">
                戻る
              </a>
            </Col>
            <Col>
              <a onClick={on_submit} className="submit">
                請求する
              </a>
            </Col>
          </Row>
        </Container>
      </PrivateSite>

      {/** 明細書写真の全画面表示モーダル */}
      <Modal
        show={receiptModal.isShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setReceiptModal({ isShow: false, receipt: null })}
        className="maitn-receipt-modal"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {receiptModal.receipt && (
            <img
              className="receipt-img"
              src={URL.createObjectURL(receiptModal.receipt)}
              alt="明細書"
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
