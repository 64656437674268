import React, { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import logo from 'contents/images/carmo-logo.svg';
import { ResultMessageView } from 'ui/organism/ResultMessage';
import { FullLoadingView } from 'ui/organism/Loading/Full';
import './style.scss';

interface Props {
  title?: string;
  code?: string;
  loading?: boolean;
  children?: ReactNode;
}

export const PublicSite: React.FC<Props> = ({
  title,
  code,
  loading,
  children,
}: Props): React.ReactElement => {
  const navigate = useNavigate();

  useEffect(() => {
    const isMaintenance =
      process.env.REACT_APP_IS_SYSTEM_MAINTENANCE === 'true';
    if (location.pathname !== '/maintenance' && isMaintenance) {
      navigate('/maintenance');
    }
  }, []);

  return (
    <>
      <Navbar bg="light" sticky="top">
        <Container className="public-site__header">
          <img src={logo} alt="おトクにマイカー 定額カルモくん" />
        </Container>
      </Navbar>

      {title && (
        <Container className="public-site__title">
          <h1>{title}</h1>
        </Container>
      )}

      {code && <ResultMessageView code={code} />}

      {/** コンテンツ表示（メイン） */}
      <div className="children">{children}</div>

      {/** ローディング表示（全画面にオーバーレイ） */}
      {loading && <FullLoadingView />}
    </>
  );
};
