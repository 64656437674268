import React, { useEffect, useState } from 'react';
import { Accordion, Col, Row, Table } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import './style.scss';
import { BillingInfo } from 'core/model/contract/BillingInfo';
import { Amount } from 'ui/molecule/Amount';
import { BillingStatus } from 'ui/molecule/BillingStatus';

export type BillingViewState = {
  from: string;
  to: string;
  count_from: number;
  count_to: number;
  month: number;
};

interface Props {
  state: BillingViewState;
}

export const BillingView: React.FC<Props> = ({
  state,
}: Props): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [billings, setBillings] = useState<BillingInfo[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  const on_click = async () => {
    if (!loaded) {
      const api = new ContractAPI<BillingInfo[]>({
        path: CONTRACTORS_API_PATH.BILLING,
        params: {
          member_id,
          contract_id,
          from: state.count_from,
          to: state.count_to,
        },
        member_id,
      });

      if (await api.get()) {
        const res = api.getResponse();
        if (res) {
          setBillings(res);
        }
      }

      setLoaded(true);
    }
  };

  return (
    <Row className="billing">
      <Col>
        <Accordion>
          <Accordion.Item eventKey={`ac-${state.month}`}>
            <Accordion.Header onClick={on_click}>
              <div className="billing__month">
                <p>{state.month}ヶ月</p>
                <p>
                  {state.from} 〜 {state.to}
                </p>
              </div>
            </Accordion.Header>
            <Accordion.Body>
              {!loaded ? (
                <PartLoadingView />
              ) : (
                <>
                  {billings.length > 0 ? (
                    <div className="billing__table">
                      <Table>
                        <thead>
                          <tr>
                            <th>請求回数</th>
                            <th>請求額</th>
                            <th>支払日</th>
                            <th>入金状況</th>
                          </tr>
                        </thead>
                        <tbody>
                          {billings.map((billing, index) => {
                            return (
                              <tr key={`billing-month-${state.month}-${index}`}>
                                <td>第 {billing.billing_count} 回</td>
                                <td>
                                  <Amount amount={billing.total_amount} />
                                </td>
                                <td>{billing.transfer_date || '---'}</td>
                                <td>
                                  <BillingStatus status={billing.status} />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div className="not-found">請求情報が読み込めません。</div>
                  )}
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Col>
    </Row>
  );
};
