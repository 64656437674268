import React, { useEffect, useState } from 'react';
import { REQUEST_ROUTE } from 'core/const/RequestRoute';
import './style.scss';

interface Props {
  request_route: number;
}

/**
 * 請求経路
 */
export const MaintRequestRoute: React.FC<Props> = ({
  request_route,
}: Props): React.ReactElement => {
  const [text, setText] = useState<string>();

  useEffect(() => {
    switch (request_route) {
      case REQUEST_ROUTE.WEB_APP:
        // WEBアプリ経由は出力不要
        break;
      case REQUEST_ROUTE.MAIL:
        setText('メール');
        break;
      case REQUEST_ROUTE.LINE:
        setText('LINE');
        break;
      case REQUEST_ROUTE.LETTER:
        setText('書面');
        break;
      case REQUEST_ROUTE.NATIVE_APP:
        setText('旧アプリ');
        break;
      case REQUEST_ROUTE.ETC:
        setText('サポートデスク');
        break;
      default:
        setText('サポートデスク');
        break;
    }
  }, []);
  return (
    <>
      {request_route !== REQUEST_ROUTE.WEB_APP && (
        <p className="request-route">
          この請求は <span>{text}</span> にて受付しております。
        </p>
      )}
    </>
  );
};
