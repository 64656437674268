import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Col, Container, ListGroup, Row, Stack } from 'react-bootstrap';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { CampaignType } from 'core/enum/CampaignType';
import { CampaignInfo } from 'core/model/contract/CampaignInfo';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { Link } from 'react-router-dom';
import './style.scss';
import { PartLoadingView } from '../Loading/Part';

/**
 * キャンペーン情報を表示する
 */
export const CampaignView: React.FC = () => {
  const [cookies] = useCookies(['contract']);
  const [campaigns, setCampaigns] = useState<CampaignInfo[]>([]);
  const [member_id, setMemberId] = useState<string>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<CampaignInfo[]>({
          path: CONTRACTORS_API_PATH.CAMPAIGN,
          params: { limit: 5 },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            setCampaigns(response);
          }
        }
        setLoaded(true);
      })();
    }
  }, [member_id]);

  return (
    <Container className="home-campaign">
      <Row className="home-campaign__title">
        <p>キャンペーン</p>
        <p>Coupon&Campaign</p>
      </Row>

      {!loaded ? (
        <PartLoadingView />
      ) : (
        <>
          {campaigns.length > 0 ? (
            <>
              <Row className="home-campaign__list">
                <Col>
                  <ListGroup>
                    {campaigns.map((row, index) => {
                      return (
                        <ListGroup.Item key={`campaign-${index}`}>
                          <Stack direction="horizontal">
                            <p>{CampaignType[row.publish_type]}</p>
                            <p>{row.publish_text}</p>
                          </Stack>
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                </Col>
              </Row>
              <Row className="home-campaign__link">
                <Col>
                  <Link to="/campaign">キャンペーン一覧</Link>
                </Col>
              </Row>
            </>
          ) : (
            <Row className="home-campaign__none">
              <Col>
                <p>現在、キャンペーンはありません</p>
              </Col>
            </Row>
          )}
        </>
      )}
    </Container>
  );
};
