import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

interface Props {
  date: string;
}

/**
 * yyyy/MM/dd
 *
 * @param date
 */
export const DateFormmat: React.FC<Props> = ({
  date,
}: Props): React.ReactElement => {
  const [formatted, setFormatted] = useState<string>('---');
  useEffect(() => {
    setFormatted(format(new Date(date), 'yyyy/MM/dd'));
  });
  return <span>{formatted}</span>;
};
