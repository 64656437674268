import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { addDays, addYears, format, isAfter } from 'date-fns';
import { PrivateSite } from 'ui/template/PrivateSite';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { Col, Container, Row } from 'react-bootstrap';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { HistoryViewState, MaintHistoryView } from 'ui/organism/MaintHistory';

export const MaintHistoryPage = () => {
  const [cookies] = useCookies(['contract']);
  const [lease_date, setLeaseDate] = useState<string>('');
  const [years, setYears] = useState<number>();
  const [viewStates, setViewState] = useState<HistoryViewState[]>();

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setLeaseDate(cookieContract.lease_date);
      setYears(cookieContract.contract_months / 12);
    }
  }, []);

  useEffect(() => {
    if (years) {
      const now = new Date();
      const tmp: HistoryViewState[] = [];
      for (let i = 0; i < years; i = i + 1) {
        const from = addYears(new Date(lease_date), i);
        const to = addDays(addYears(from, 1), -1);

        // ※fromが現在日時を超えた場合のみ設定
        if (isAfter(now, from)) {
          tmp.unshift({
            from: format(from, 'yyyy/MM/dd'),
            to: format(to, 'yyyy/MM/dd'),
            month: (i + 1) * 12,
          });
        }
      }
      setViewState(tmp);
    }
  }, [years]);

  return (
    <PrivateSite title="メンテナンス履歴">
      <Container>
        <Row className="row__page-description">
          <Col>
            <p>
              {format(new Date(), 'yyyy/MM/dd')}
              時点までのご利用履歴をご確認いただけます。
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="headline">ご利用履歴</div>
          </Col>
        </Row>

        {!viewStates ? (
          <PartLoadingView />
        ) : (
          <>
            {viewStates.map((viewState, index) => {
              return (
                <MaintHistoryView
                  state={viewState}
                  key={`history-view-${index}`}
                />
              );
            })}
          </>
        )}
      </Container>
    </PrivateSite>
  );
};
