import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { ContractBank } from 'core/model/contract/ContractBank';
import { PartLoadingView } from '../Loading/Part';
import { DepositCode } from 'ui/molecule/DepositCode';
import './style.scss';

interface Props {
  bank_props?: {
    bank_name: string;
    branch_name: string;
    deposit_code: number;
    account_holder: string;
    account_number_mask: string;
  };
}

/**
 * 口座情報の表示
 */
export const ContractBankView: React.FC<Props> = ({
  bank_props,
}): React.ReactElement => {
  const [cookies] = useCookies(['contract']);
  const [bank, setBank] = useState<ContractBank>();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (bank_props) {
      setBank({ ...bank_props, bank_code: '' });
      setLoaded(true);
    } else {
      const cookieInfo = decCookieContract(cookies.contract);
      if (cookieInfo) {
        (async () => {
          const api = new ContractAPI<ContractBank>({
            path: CONTRACTORS_API_PATH.BANK,
            params: {
              member_id: cookieInfo.member_id,
              contract_id: cookieInfo.contract_id,
            },
            member_id: cookieInfo.member_id,
          });
          if (await api.get()) {
            const bank = api.getResponse();
            if (bank) {
              setBank(bank);
            }
          }
          setLoaded(true);
        })();
      }
    }
  }, []);

  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className="headline">毎月のお引落口座</div>
          </Col>
        </Row>

        {!loaded ? (
          <PartLoadingView />
        ) : (
          bank && (
            <Row>
              <Col>
                <Table className="bank-info">
                  <tbody>
                    <tr>
                      <td>金融機関名</td>
                      <td>{bank.bank_name}</td>
                    </tr>
                    <tr>
                      <td>支店名</td>
                      <td>{bank.branch_name}</td>
                    </tr>
                    <tr>
                      <td>科目</td>
                      <td>
                        <DepositCode code={bank.deposit_code} />
                      </td>
                    </tr>
                    <tr>
                      <td>口座番号</td>
                      <td>{bank.account_number_mask}</td>
                    </tr>
                    <tr>
                      <td>口座名義</td>
                      <td>{bank.account_holder}</td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          )
        )}
      </Container>
    </>
  );
};
