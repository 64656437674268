/**
 * 文字列をマスキングする
 * @param str
 * @param look
 * @returns
 */
export const masking = (str: string, look: number) => {
  const slice = str.slice(look * -1);
  return slice.padStart(str.length, '*');
};
