import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { RESULT_CODE } from 'core/const/ResultCode';
import './style.scss';

interface Props {
  code: string;
}

/**
 * リザルトコードに応じたメッセージを表示する
 */
export const ResultMessageView: React.FC<Props> = ({
  code,
}: Props): React.ReactElement => {
  const [style, setStyle] = useState<string>();
  const [message, setMessage] = useState<string>();

  const MESSAGE_TYPE = {
    success: 'success',
    notice: 'notice',
    warning: 'warning',
  };

  useEffect(() => {
    switch (code) {
      case RESULT_CODE.LOGIN_MISTAKE:
        setMessage(
          'ご入力いただいたメールアドレスまたは、パスワードに誤りがあります。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.CONTRACT_NOT_FOUND:
        setMessage(
          '有効な契約がありません。定額カルモくんサポートデスクまでお問い合わせください。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.USER_LOCK:
        setMessage(
          'ログインを制限しております。定額カルモくんサポートデスクまでお問い合わせください。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.LOGOUT:
        setMessage('ログアウトしました。');
        setStyle(MESSAGE_TYPE.notice);
        break;
      case RESULT_CODE.SESSION_ERROR:
        setMessage(
          '前回のログインから一定時間経過したのでログアウトしました。再度ログインをしてください。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.LOST_SESSION:
        // エラーメッセージは出さない
        break;
      case RESULT_CODE.LOGIN_EXPIRED_ENDED:
        setMessage('契約期間を過ぎているためログインできません。');
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.LOGIN_EXPIRED_CANCELED:
        setMessage('解約済みのためログインできません。');
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.PASSWORD_CHANGED:
      case RESULT_CODE.RESET_PASSWORD_CHANGED:
        setMessage('パスワード設定が完了しました。');
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.UNMATCH_PASSWORD:
        setMessage('現在のパスワードが違います。再度入力をお願いします。');
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.SAME_PASSWORD:
        setMessage('現在のパスワードと異なるパスワードを入力してください。');
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.MAINT_REQUEST_CREATED:
      case RESULT_CODE.MAINT_REQUEST_UPDATED:
        setMessage('メンテナンス費用請求が完了しました。');
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.MAINT_REQUEST_CANCELED:
        setMessage('メンテナンス費用請求を取消しました。');
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.LOGIN_SELECT_MISTAKE:
      case RESULT_CODE.PASSWORD_CHANGE_FAILED:
      case RESULT_CODE.RESET_PASSWORD_CHANGE_FAILED:
      case RESULT_CODE.MAINT_REQUEST_NOTFOUND:
      case RESULT_CODE.MAINT_REQUEST_CREATE_FAILED:
      case RESULT_CODE.MAINT_REQUEST_UPDATE_FAILED:
      case RESULT_CODE.SYSTEM_ERROR:
        setMessage(
          'システムエラーが発生しました。お時間をおいて、再度操作をお願いいたします。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.RESET_MAIL_SEND_FAILED:
        // 実際にはエラーでメールを送っていない。攻撃者へのヒントにならないように濁す。
        setMessage(
          '登録されているメールアドレスにパスワード再設定メールをお送りしました。メールが届いているかご確認ください。届いていない場合はメールアドレスに誤りがないかどうかご確認の上、再度操作をお願いいたします。',
        );
        setStyle(MESSAGE_TYPE.success);
        break;
      case RESULT_CODE.RESET_VERIFY_FAILED:
        setMessage(
          'URLの有効期限が過ぎています。再度「パスワードをお忘れの方はこちら」から操作をお願いいたします。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.BACKDOOR_LOGIN_MISTAKE:
        setMessage('【サポート専用】会員が見つかりません。');
        setStyle(MESSAGE_TYPE.warning);
        break;
      case RESULT_CODE.BACKDOOR_CANNOT_EXECUTE:
        setMessage(
          '【サポート専用】データに影響を及ぼす機能は実行できません。',
        );
        setStyle(MESSAGE_TYPE.warning);
        break;
      default:
        break;
    }
  }, [code]);

  return (
    <>
      {message && (
        <Container>
          <Row>
            <Col>
              <div className={`result-message ${style}`}>{message}</div>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
