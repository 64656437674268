import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { PublicSite } from 'ui/template/PublicSite';
import IconCall from 'contents/images/benefit/img-footer-call.svg';
import IconMail from 'contents/images/benefit/icon-mail.svg';
import './style.scss';

export const Contact: React.FC = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <PublicSite title="お問合せ" code={code} loading={loading}>
      <Container>
        <Row className="row__page-description">
          <Col>
            <p>
              メールアドレス・パスワードどちらもお忘れの方は、定額カルモくんサポートデスクにご連絡ください。
              <br />
              <br />
              ※お問い合わせは本LINEでも承っております。
            </p>
          </Col>
        </Row>

        <Row className="contact__mail">
          <Col>
            <a
              href="https://carmo-kun.jp/contact/"
              target="_blank"
              rel="noopener"
            >
              <p>
                <span>
                  <img
                    src={IconMail}
                    className="footer-link-mail-icon"
                    alt="メールフォームで相談する"
                  />
                </span>
                <span>メールフォームで相談する</span>
              </p>
            </a>
          </Col>
        </Row>

        <Row className="contact__tel">
          <Col>
            <div>
              <a href="tel:0120-559-442">
                <img
                  className="footer-tel-image"
                  src={IconCall}
                  alt="0120-559-442"
                />
              </a>
            </div>

            <div>
              <span>
                受付時間：平日10:00〜19:00（土日・祝日・年末年始休み）
              </span>
            </div>
          </Col>
        </Row>

        <Row className="contact__faq">
          <Col>
            <a
              href="https://app.carmo-kun.jp/contents/faq"
              target="_blank"
              rel="noopener"
            >
              よくあるご質問はこちら
            </a>
          </Col>
        </Row>
      </Container>
    </PublicSite>
  );
};
