import { useFormik } from 'formik';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrivateSite } from 'ui/template/PrivateSite';
import * as yup from 'yup';
import './style.scss';

type NavigateState = {
  member_id: string;
  auth: string;
  referrer?: string;
  code?: string;
};

export const InitPasswordSetting: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateState = location.state as NavigateState;

  const onSubmit = async (formInputs: {}) => {
    const params = JSON.parse(JSON.stringify(formInputs));
    navigate('/password/init/execute', {
      state: {
        member_id: navigateState.member_id,
        auth: navigateState.auth,
        referrer: navigateState.referrer,
        new_password: params.new_password,
      },
    });
  };

  const validationSchema = yup.object().shape({
    new_password: yup
      .string()
      .min(8, '8文字以上で入力してください。')
      .matches(/^[a-zA-Z0-9]+$/, '半角英数字で入力してください。')
      .required('必須項目です。'),
    new_password_confirm: yup
      .string()
      .min(8, '8文字以上で入力してください。')
      .matches(/^[a-zA-Z0-9]+$/, '半角英数字で入力してください。')
      .required('必須項目です。')
      .oneOf([yup.ref('new_password'), ''], '新しいパスワードが一致しません。'),
  });

  const formik = useFormik({
    initialValues: {
      new_password: '',
      new_password_confirm: '',
    },
    onSubmit,
    validationSchema,
  });

  return (
    <PrivateSite title="パスワード初期設定" code={navigateState?.code}>
      <Container>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Container>
            <Row className="row__form new-password">
              <Col>
                <Form.Group controlId="new_password">
                  <Form.Label>新しいパスワード</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="****"
                    isInvalid={
                      !!formik.touched.new_password &&
                      !!formik.errors.new_password
                    }
                    {...formik.getFieldProps('new_password')}
                  />
                  {formik.touched.new_password && formik.errors.new_password ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.new_password}
                    </Form.Control.Feedback>
                  ) : null}
                  <div className="password-condition">
                    <p>【パスワード条件】</p>
                    <ul>
                      <li>半角英数字のみ使用可能（a～z・A～Z・0～9）</li>
                      <li>8文字以上</li>
                    </ul>
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="row__form">
              <Col>
                <Form.Group controlId="new_password_confirm">
                  <Form.Label>新しいパスワード（確認用）</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="****"
                    isInvalid={
                      !!formik.touched.new_password_confirm &&
                      !!formik.errors.new_password_confirm
                    }
                    {...formik.getFieldProps('new_password_confirm')}
                  />
                  {formik.touched.new_password_confirm &&
                  formik.errors.new_password_confirm ? (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.new_password_confirm}
                    </Form.Control.Feedback>
                  ) : null}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <div className="text-center">
                  <Button type="submit">パスワードを変更</Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      </Container>
    </PrivateSite>
  );
};
