import React from 'react';
import './style.scss';

/**
 * 「Loading...」を全画面表示
 */
export const FullLoadingView: React.FC = () => {
  return (
    <div className="loading">
      <div className="loading__spinner">
        <span>Loading...</span>
      </div>
    </div>
  );
};
