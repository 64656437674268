import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { PublicSite } from 'ui/template/PublicSite';
import { useCookies } from 'react-cookie';
import { addDays } from 'date-fns';
import { encCookieContract } from 'compornents/functions/util/Cookie';
import { ContractDetail } from 'core/model/contract/Contract';
import { RESULT_CODE } from 'core/const/ResultCode';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import './style.scss';
import { isValidContract } from 'compornents/functions/contract/ValidContract';

type NavigateState = {
  member_id: string;
};

/**
 * 契約選択画面
 */
export const LoginSelect = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const member_id = navigateState.member_id;
  const [, setCookie, removeCookie] = useCookies(['contract']);
  const [code, setCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<ContractDetail[]>([]);

  useEffect(() => {
    if (!member_id) {
      navigate('/login', {
        state: { code: RESULT_CODE.LIGIN_SYSTEM_ERROR },
      });
      return;
    }
    (async () => {
      setLoading(true);
      const api = new ContractAPI<ContractDetail[]>({
        path: CONTRACTORS_API_PATH.CONTRACT_DETAIL,
        params: { member_id },
        member_id,
      });
      const result = await api.get();
      if (result.success && result.statusCode === 200) {
        const response = api.getResponse();
        if (response) {
          const valid_contracts = response.filter((row) => {
            return isValidContract(row.contract);
          });
          if (valid_contracts.length === 0) {
            navigate('/login', {
              state: { code: RESULT_CODE.CONTRACT_NOT_FOUND },
            });
          }
          setContracts(valid_contracts);
        }
      } else {
        navigate('/login', { state: { code: RESULT_CODE.SYSTEM_ERROR } });
      }
      setLoading(false);
    })();
  }, []);

  const on_click = (index: number) => {
    setLoading(true);
    try {
      const expires = addDays(new Date(), 1);
      const contract = contracts[index].contract;
      const enc = encCookieContract({
        member_id,
        contract_id: contract.contract_id,
        distributor: contract.distributor,
        lease_date: contract.lease_date_from,
        lease_amount: contract.lease_amount,
        contract_months: contract.contract_months,
        member_name: contract.member_name,
        cost_type: contract.cost_type,
        contract_type: contract.contract_type,
        judgement: contract.judgement,
        is_multiple: true,
        sales_scheme_id: contract.sales_scheme_id,
        status: contract.status,
      });
      removeCookie('contract');
      setCookie('contract', enc, { expires });
      navigate('/home');
    } catch (e) {
      setCode(RESULT_CODE.LOGIN_SELECT_MISTAKE);
    }
    setLoading(false);
  };

  return (
    <PublicSite title="契約情報の選択" code={code} loading={loading}>
      <Container>
        {contracts.map((contract, index) => {
          return (
            <div
              className="login-contract-select"
              key={`contract-select-${index}`}
            >
              <Row className="login-contract-select__header">
                <Col>契約内容（{index + 1}）</Col>
              </Row>
              <Row>
                <Col xs={4}>メーカー</Col>
                <Col>{contract.vehicle.maker_name}</Col>
              </Row>
              <Row>
                <Col xs={4}>車種名</Col>
                <Col>{contract.vehicle.car_name}</Col>
              </Row>
              <Row>
                <Col xs={4}>リース開始日</Col>
                <Col>{contract.contract.lease_date_from}</Col>
              </Row>
              <Row>
                <Col xs={4}>契約月数</Col>
                <Col>{contract.contract.contract_months}ヶ月</Col>
              </Row>
              <Row className="login-contract-select__button">
                <Col>
                  <Button onClick={() => on_click(index)}>
                    この契約を開く
                  </Button>
                </Col>
              </Row>
            </div>
          );
        })}
      </Container>
    </PublicSite>
  );
};
