import { Benefit } from 'core/model/contract/Benefit';
import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import './style.scss';

interface Props {
  benefit: Benefit;
}

export const BenefitPanel: React.FC<Props> = ({
  benefit,
}: Props): React.ReactElement => {
  const [isNew, setIsNew] = useState<boolean>(false);
  const [isDueSoon, setIsDueSoon] = useState<boolean>(false);
  const service_url = process.env.REACT_APP_SERVICE_URL || '';
  const NEW_TERM = 30;
  const DUE_SOON_TERM = 30;

  useEffect(() => {
    const currentDate = new Date();

    // 「NEW」の表示制御
    const newLabelFrom = new Date(benefit.from_date);
    const newLabelTo = new Date(benefit.from_date);
    newLabelTo.setDate(newLabelTo.getDate() + NEW_TERM);
    if (newLabelFrom <= currentDate && newLabelTo > currentDate) {
      setIsNew(true);
    }

    // 「まもなく終了」の表示制御
    const dueSoonLabelFrom = new Date(benefit.to_date);
    dueSoonLabelFrom.setDate(dueSoonLabelFrom.getDate() - DUE_SOON_TERM);
    const dueSoonLabelTo = new Date(benefit.to_date);
    if (dueSoonLabelFrom <= currentDate && dueSoonLabelTo > currentDate) {
      setIsDueSoon(true);
    }
  }, []);

  return (
    <Card className="benefit-panel">
      {isNew ? (
        <Card.Body className="panel-label">
          <span className="is-new">NEW</span>
        </Card.Body>
      ) : isDueSoon ? (
        <Card.Body className="panel-label">
          <span className="is-due-soon">まもなく終了</span>
        </Card.Body>
      ) : (
        <Card.Body className="panel-label">
          <span className="is-blank">
            <br />
          </span>
        </Card.Body>
      )}

      <Card.Title className="panel-name">
        {benefit.service_name && `${benefit.service_name} / `}
        {benefit.benefit}
      </Card.Title>

      <Card.Title className="panel-summary">{benefit.summary}</Card.Title>

      <Card.Body className="panel-thumbnail">
        <Card.Img src={`${service_url}/${benefit.img_path}`} />
      </Card.Body>

      <Card.Body>
        <Card.Text className="panel-body">
          <Accordion defaultActiveKey={benefit.benefit_id.toString()}>
            <Accordion.Body>
              {benefit.detail.split('\n').map((row, index) => {
                return (
                  <React.Fragment key={index}>
                    {row ? <div>{row}</div> : <br />}
                  </React.Fragment>
                );
              })}
            </Accordion.Body>
            <Accordion.Header>
              <span>もっと見る</span>
            </Accordion.Header>
          </Accordion>
        </Card.Text>

        <Card.Link
          className="panel-link"
          href={benefit.link}
          target="_blank"
          rel="noopener"
          data-gtm={benefit.benefit_gtm_id}
        >
          {benefit.link_text}
        </Card.Link>
      </Card.Body>
    </Card>
  );
};
