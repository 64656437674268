import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { PrivateSite } from 'ui/template/PrivateSite';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { ContractBankView } from 'ui/organism/ContractBank';
import { Amount } from 'ui/molecule/Amount';
import './style.scss';
import { MaintHistory } from 'core/model/contract/MaintHistory';
import { MaintHistoryItem } from 'core/model/contract/MaintHistoryItem';

export const MaintHistoryDetail = () => {
  const navigate = useNavigate();
  const { maint_history_id } = useParams();
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [history, setHistory] = useState<MaintHistory>();
  const [items, setItems] = useState<MaintHistoryItem[]>([]);
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<MaintHistoryItem[]>({
          path: CONTRACTORS_API_PATH.MAINT_HISTORY_ITEM,
          params: { member_id, contract_id, maint_history_id },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            setItems(response);
          }
        } else if (result.statusCode === 401) {
          setExpired(true);
        }
      })();
    }
  }, [member_id]);

  useEffect(() => {
    if (items.length > 0) {
      const tmp: MaintHistory = { ...items[0], amount: items[0].total_amount };
      setHistory(tmp);
    }
  }, [items]);

  return (
    <PrivateSite title="メンテナンス利用履歴" expired={expired}>
      <Container className="maint-history-item">
        <Row>
          <Col>
            <div className="headline">ご利用情報</div>
          </Col>
        </Row>

        {!history ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <dl>
                <dt>利用日</dt>
                <dd>{history.use_date}</dd>
                <dt>振込日</dt>
                <dd>{history.transfer_date}</dd>
                <dt>振込金額</dt>
                <dd>
                  <Amount amount={history.amount} />
                </dd>
              </dl>
            </Col>
          </Row>
        )}

        <Row>
          <Col>
            <div className="headline">メンテナンス項目</div>
          </Col>
        </Row>

        {items.length === 0 ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <Table className="history-detail__item">
                <thead>
                  <tr>
                    <th>項目名</th>
                    <th>振込金額</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, index) => {
                    return (
                      <tr key={`maint-item-${index}`}>
                        <td>{item.item_name}</td>
                        <td>
                          <Amount amount={item.amount} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Container>

      {history && (
        <ContractBankView
          bank_props={{
            bank_name: history.bank_name,
            branch_name: history.branch_name,
            deposit_code: history.deposit_code,
            account_holder: history.account_holder,
            account_number_mask: history.account_number_mask,
          }}
        />
      )}

      <Container>
        <Row className="history-detail__backward">
          <Col>
            <Link to="/maint/history">一覧に戻る</Link>
          </Col>
        </Row>
      </Container>
    </PrivateSite>
  );
};
