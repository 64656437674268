import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { addDays } from 'date-fns';
import {
  decCookieContract,
  encCookieContract,
} from 'compornents/functions/util/Cookie';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { ContractDetail } from 'core/model/contract/Contract';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { PrivateSite } from 'ui/template/PrivateSite';
import './style.scss';
import { isValidContract } from 'compornents/functions/contract/ValidContract';
import { RESULT_CODE } from 'core/const/ResultCode';

export const ContractSelect = () => {
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [expired, setExpired] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [contracts, setContracts] = useState<ContractDetail[]>([]);

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (cookieContract) {
      setMemberId(cookieContract.member_id);
    }
  }, []);

  useEffect(() => {
    if (member_id)
      (async () => {
        setLoading(true);
        const api = new ContractAPI<ContractDetail[]>({
          path: CONTRACTORS_API_PATH.CONTRACT_DETAIL,
          params: { member_id },
          member_id,
        });
        const result = await api.get();
        if (result.success && result.statusCode === 200) {
          const response = api.getResponse();
          if (response) {
            const valid_contracts = response.filter((row) => {
              return isValidContract(row.contract);
            });
            if (valid_contracts.length === 0) {
              navigate('/login', {
                state: { code: RESULT_CODE.CONTRACT_NOT_FOUND },
              });
            }
            setContracts(valid_contracts);
          }
        } else if (result.statusCode === 401) {
          setExpired(true);
        }
        setLoading(false);
      })();
  }, [member_id]);

  const on_click = (index: number) => {
    const expires = addDays(new Date(), 1);
    const contract = contracts[index].contract;
    const enc = encCookieContract({
      member_id: member_id || '',
      contract_id: contract.contract_id,
      distributor: contract.distributor,
      lease_date: contract.lease_date_from,
      lease_amount: contract.lease_amount,
      contract_months: contract.contract_months,
      member_name: contract.member_name,
      cost_type: contract.cost_type,
      contract_type: contract.contract_type,
      judgement: contract.judgement,
      is_multiple: true,
      sales_scheme_id: contract.sales_scheme_id,
      status: contract.status,
    });
    setCookie('contract', enc, { expires });
    navigate('/home');
  };

  return (
    <PrivateSite title="契約情報の選択" expired={expired} loading={loading}>
      <Container>
        {contracts.map((contract, index) => {
          return (
            <div className="contract-select" key={`contract-select-${index}`}>
              <Row className="contract-select__header">
                <Col>契約内容（{index + 1}）</Col>
              </Row>
              <Row>
                <Col xs={4}>メーカー</Col>
                <Col>{contract.vehicle.maker_name}</Col>
              </Row>
              <Row>
                <Col xs={4}>車種名</Col>
                <Col>{contract.vehicle.car_name}</Col>
              </Row>
              <Row>
                <Col xs={4}>リース開始日</Col>
                <Col>{contract.contract.lease_date_from}</Col>
              </Row>
              <Row>
                <Col xs={4}>契約月数</Col>
                <Col>{contract.contract.contract_months}ヶ月</Col>
              </Row>
              <Row className="login-contract-select__button">
                <Col>
                  <Button onClick={() => on_click(index)}>
                    この契約を開く
                  </Button>
                </Col>
              </Row>
            </div>
          );
        })}
      </Container>
    </PrivateSite>
  );
};
