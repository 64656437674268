import { Col, Container, Row } from 'react-bootstrap';
import ImageHowToUseFirst from 'contents/images/benefit/img-howto-first.svg';
import ImageHowToUseSecond from 'contents/images/benefit/img-howto-second.svg';
import ImageHowToUseThird from 'contents/images/benefit/img-howto-third.svg';
import ImageCampaign from 'contents/images/benefit/img-campaign_20240301.png';
import ImageCampaignSp from 'contents/images/benefit/img-banner-refer-sp-small.png';
import ImageBannerMagazine from 'contents/images/benefit/img-banner-magazine.png';
import { BenefitPanel } from 'ui/organism/BenefitPanel';
import { Benefit } from 'core/model/contract/Benefit';
import { useEffect, useState } from 'react';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { PrivateSite } from 'ui/template/PrivateSite';
import main_visual from 'contents/images/benefit/img-mainvisual-pc.png';
import './style.scss';

export const BenefitPage = () => {
  const [benefits, setBenefits] = useState<Benefit[]>([]);
  const [cookies] = useCookies(['contract']);
  const navigate = useNavigate();

  useEffect(() => {
    const s = document.createElement('script');
    s.setAttribute('src', 'https://platform.twitter.com/widgets.js');
    document.body.appendChild(s);
  }, []);

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (!cookieContract) {
      navigate('/login');
      return; // 型エラー回避用のreturn
    }
    (async () => {
      const api = new ContractAPI<Benefit[]>({
        path: CONTRACTORS_API_PATH.BENEFIT,
        params: {},
        member_id: cookieContract.member_id,
      });
      const result = await api.get();
      if (result.success && result.statusCode === 200) {
        const response = api.getResponse();
        setBenefits(response ? response : []);
      } else if (result.statusCode === 401) {
        navigate('/home');
      } else {
        navigate('/home', { state: { code: '' } });
      }
    })();
  }, []);

  return (
    <PrivateSite>
      <Container className="benefit">
        <Row className="benefit-header__title">
          <Col>
            <h1>
              <p>ありがとうの気持ちを込めて！</p>
              <p>カルモご契約者様だけの特別特典！</p>
            </h1>
          </Col>
        </Row>
        <Row className="benefit-header__main-visual">
          <Col>
            <img src={main_visual} />
            <div className="benefit-header__campaign">
              <a
                href="https://carmo-kun.jp/lp/refer3/"
                target="_blank"
                rel="noopener"
              >
                <img
                  className="campaign-thumbnail-item"
                  src={ImageCampaignSp}
                  alt="カルモくん公式キャンペーン"
                />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="benefit">
        <Row>
          <Col>
            <div className="benefit-index__box">
              <p className="benefit-index__title">目次</p>
              <ul className="benefit-index__list">
                <li>
                  <a href="#section-howto">1.特典の使い方</a>
                </li>
                <li>
                  <a href="#section-list">2.特典一覧</a>
                </li>
                <li>
                  <a href="#section-campaign">3.【2万円GET】紹介キャンペーン</a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="benefit">
        <Row className="benefit-description">
          <Col>
            <p>
              カーライフを豊かに、さらに日常を楽しんでほしい。
              <br />
              そんな想いと日頃の感謝の気持をこめて、カルモ契約者さま限定の特別特典をご用意しました。
              <br />
              クルマ関連はもちろん、おでかけや様々なシーンでぜひご活用ください。
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="benefit" id="section-howto">
        <Row>
          <Col>
            <h2 className="benefit__heading">特典の使い方</h2>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={4}>
            <div className="benefit-howto__step step1">
              <img
                src={ImageHowToUseFirst}
                alt="ご希望の特典の利用方法をチェック！"
              />
              <p>ご希望の特典の利用方法をチェック！</p>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={4}>
            <div className="benefit-howto__step step2">
              <img
                src={ImageHowToUseSecond}
                alt="ご希望の特典の利用方法をチェック！"
              />
              <p>「特典をつかう」を押して、提携企業サイトへ移動</p>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={4}>
            <div className="benefit-howto__step step3">
              <img
                src={ImageHowToUseThird}
                alt="ご希望の特典の利用方法をチェック！"
              />
              <p>提携企業サイトの案内に従ってお申込み</p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="benefit" id="section-list">
        <Row>
          <Col>
            <h2 className="benefit__heading">特典一覧</h2>
          </Col>
        </Row>

        <Row>
          <Col>
            {benefits.length === 0 ? (
              <PartLoadingView />
            ) : (
              <Row>
                {benefits.map((row, index) => {
                  return (
                    <Col
                      xs={12}
                      sm={12}
                      lg={4}
                      className="benefit-panel__list"
                      key={`benefit-panel-${index}`}
                    >
                      <BenefitPanel benefit={row} />
                    </Col>
                  );
                })}
              </Row>
            )}
          </Col>
        </Row>
      </Container>

      <Container className="benefit" id="section-campaign">
        <Row className="benefit-campaign__title">
          <Col>
            <p>契約者様限定！</p>
            <p>カルモくん公式キャンペーン</p>
          </Col>
        </Row>

        <Row className="benefit-campaign__thumbnail">
          <Col>
            <a
              href="https://carmo-kun.jp/lp/refer3/"
              target="_blank"
              rel="noopener"
            >
              <img src={ImageCampaign} alt="カルモくん公式キャンペーン" />
            </a>
          </Col>
        </Row>
      </Container>

      <Container className="benefit">
        <Row>
          <Col>
            <h2 className="benefit__heading">SNSでも情報発信中！</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="benefit-sns__timeline">
              <a
                className="twitter-timeline"
                data-lang="ja"
                data-width="360"
                data-height="500"
                href="https://twitter.com/carmomycar?ref_src=twsrc%5Etfw"
              >
                Tweets by carmomycar
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="benefit">
        <Row>
          <Col>
            <h2 className="benefit__heading">カルモマガジン</h2>
          </Col>
        </Row>
        <Row className="benefit-magazine__thumbnail">
          <Col>
            <a href="https://car-mo.jp/" target="_blank" rel="noopener">
              <img
                src={ImageBannerMagazine}
                alt="クルマ選びとカーライフを応援するWebマガジン カルモマガジン"
              />
            </a>
          </Col>
        </Row>
      </Container>
    </PrivateSite>
  );
};
