import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PublicSite } from 'ui/template/PublicSite';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import './style.scss';

export const SystemMaintenance: React.FC = () => {
  const from_at = format(
    new Date(process.env.REACT_APP_SYSTEM_MAINTENANCE_FROM || ''),
    'yyyy年MM月dd日（E） HH:mm',
    { locale: ja },
  );
  const to_at = format(
    new Date(process.env.REACT_APP_SYSTEM_MAINTENANCE_TO || ''),
    'yyyy年MM月dd日（E） HH:mm',
    { locale: ja },
  );
  return (
    <PublicSite title="メンテナンス中">
      <Container className="system-maintenance">
        <Row className="row__page-description">
          <Col>
            <p>
              ただいまメンテナンスのため一時サービスを停止しております。
              大変ご不便をおかけいたしますが、今しばらくお待ちください。
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="system-maintenance__time">
              <h3>【メンテナンス時間】</h3>
              <p>{from_at}</p>
              <p className="between">
                <span>〜</span>
              </p>
              <p>
                {to_at}
                <span className="end">（終了予定）</span>
              </p>
            </div>

            <p className="system-maintenance__attention">
              ※メンテナンスが終了次第サービスをご利用いただけますが、状況によっては延長となる場合もございます。
            </p>
          </Col>
        </Row>
      </Container>
    </PublicSite>
  );
};
