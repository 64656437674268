import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import reqeust_step_1 from 'contents/images/icon/request-step-1.svg';
import reqeust_step_2 from 'contents/images/icon/request-step-2.svg';
import reqeust_step_3 from 'contents/images/icon/request-step-3.svg';
import './style.scss';

interface Props {
  step: number;
}

export const RequestStep: React.FC<Props> = ({ step }: Props) => {
  return (
    <div className="request-step">
      {step === 1 && <img src={reqeust_step_1} />}
      {step === 2 && <img src={reqeust_step_2} />}
      {step === 3 && <img src={reqeust_step_3} />}
    </div>
  );
};
