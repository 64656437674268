import React from 'react';
import './style.scss';
import { Col, Row } from 'react-bootstrap';

interface Props {
  vehicle_warranty_id: number;
}

export const WarrantyDocuments: React.FC<Props> = ({
  vehicle_warranty_id,
}): React.ReactElement => {
  const service_url = process.env.REACT_APP_SERVICE_URL || '';

  return (
    <>
      {vehicle_warranty_id === 3 && (
        <Row className="warranty-document">
          <Col>
            <a
              href={`${service_url}/public/warranty/carmo-warranty-plus-guidelines_20241128.pdf`}
              className="external-link"
              target="_blank"
            >
              ガイドラインはこちら
            </a>
          </Col>
          <Col>
            <a
              href={`${service_url}/public/warranty/carmo-warranty-plus-317items_20241127.pdf`}
              className="external-link"
              target="_blank"
            >
              保証項目はこちら
            </a>
          </Col>
        </Row>
      )}
    </>
  );
};
