import React from 'react';
import img from 'contents/images/maint/attachment-statement.png';
import sampleImage1 from 'contents/images/maint/rejected-sample-1.png';
import sampleImage2 from 'contents/images/maint/rejected-sample-2.png';
import check from 'contents/images/icon/check.svg';
import cross from 'contents/images/icon/cross.svg';
import documentSamples from 'contents/images/maint/unavailable-document-samples.svg';
import './style.scss';
import {
  Container,
  Col,
  Row,
} from 'react-bootstrap';

export const ReceiptDescription: React.FC = () => {
  const isImage = true;

  return (
    <>
      {isImage ? (
        <div className='attachment-statement-image'>
          <img src={ img } />
        </div>
      ) : (
        <div className='attachment-statement'>
            <p>印字された明細書を撮影いただく際、<span className='carmo_red'>以下の項目が全て入るように書類全体を撮影してください。</span>裏面や複数枚ある場合は何枚かに分けて撮影してください。</p>
            
            <Container className="items">
              <Row className="item">
                <div className='contents'>
                  <img src={check} />
                  <span className='carmo_red'>点検実施日</span>
                </div>
              </Row>
              <Row className="item">
                <div className='contents'>
                  <img src={check} />
                  <span className='carmo_red'>店舗名</span>
                </div>
              </Row>
              <Row className="item">
                <div className='contents'>
                  <img src={check} />
                  <span className='carmo_red'>宛名（ご契約者名）</span>
                </div>
              </Row>
              <Row className="item">
                <div className='contents'>
                  <img src={check} />
                  <span className='carmo_red'>点検項目</span>
                </div>
              </Row>
              <Row className="item">
                <div className='contents'>
                  <img src={check} />
                  <span className='carmo_red'>項目ごとの金額</span>
                </div>
              </Row>
            </Container>

            <Container className="important-point">
              <Row className="important-point__title">
                <p className='main'>明細書撮影時の注意点</p>
              </Row>
              <Row className="important-point__content">
                <p>詳細が見切れているものや、ぼやけていたり不鮮明な写真の場合、<span className="carmo_red">内容が確認できないため、申請いただいても不受理となってしまいます</span>のでご注意ください。</p>
                <Row className='important-point__content-item'>
                  <Col>
                    <img src={sampleImage1} />
                  </Col>
                  <Col>
                    <div className='comment'>
                      <p>詳細が見切れて</p>
                      <p>内容確認できない</p>
                    </div>
                    <img className='icon' src={cross} />
                  </Col>
                </Row>
                <Row className='important-point__content-item'>
                  <Col>
                    <img src={sampleImage2} />
                  </Col>
                  <Col>
                    <div className='comment'>
                      <p>不鮮明で内容が</p>
                      <p>確認できない</p>
                    </div>
                    <img className='icon' src={cross} />
                  </Col>
                </Row>
              </Row>

              <Row className="important-point__title">
                <p>ご利用いただけない書類</p>
              </Row>
              <Row className="important-point__content">
                <p>見積書・領収書・売上伝票は<span className='carmo_red'>明細書としてご利用いただけない</span>のでご注意ください。</p>
                <Row className='important-point__content-item'>
                  <img src={documentSamples} />
                </Row>
              </Row>
            </Container>
        </div>
      )}
    </>
  );
};
