import React, { ReactNode, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import { ResultMessageView } from 'ui/organism/ResultMessage';
import { Header } from 'ui/organism/Header';
import { FullLoadingView } from 'ui/organism/Loading/Full';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import './style.scss';
import { RESULT_CODE } from 'core/const/ResultCode';
import { props } from 'core/Props';
import { APP_MODE } from 'core/const/AppMode';

interface Props {
  title?: string;
  code?: string;
  loading?: boolean;
  children?: ReactNode;
  backward_state?: {};
  expired?: boolean;
}

export const PrivateSite: React.FC<Props> = ({
  title,
  code,
  loading,
  children,
  backward_state,
  expired,
}: Props): React.ReactElement => {
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, , removeCookie] = useCookies(['contract']);
  const [isToTop, setIsToTop] = useState<boolean>(true);
  const isMaintenance = props.IS_SYSTEM_MAINTENANCE === 'true';

  useEffect(() => {
    if (props.APP_MODE === APP_MODE.BACKDOOR) {
      if (
        location.pathname.includes('execute') ||
        location.pathname.includes('cancel')
      ) {
        navigate('/home', {
          state: { code: RESULT_CODE.BACKDOOR_CANNOT_EXECUTE },
        });
      }
    } else if (isMaintenance) {
      navigate('/maintenance');
    }
  }, []);

  useEffect(() => {
    const cookieContract = decCookieContract(cookies.contract);
    if (!cookieContract) {
      navigate('/login', {
        state: {
          code: RESULT_CODE.LOST_SESSION,
          referrer: getReferrer(),
        },
      });
    }

    if (location.pathname === '/home' || location.pathname === '/logout') {
      setIsToTop(false);
    }
  }, []);

  useEffect(() => {
    if (expired) {
      removeCookie('contract');

      navigate('/login', {
        state: {
          code: RESULT_CODE.SESSION_ERROR,
          referrer: getReferrer(),
        },
      });
    }
  }, [expired]);

  const getReferrer = (): string => {
    if (location.pathname === '/logout') {
      return '/home';
    } else {
      return location.pathname;
    }
  };

  return (
    <>
      {props.APP_MODE === APP_MODE.BACKDOOR && <div className="backdoor-str" />}

      {/** ヘッダー表示（画面上部に固定） */}
      <Header backward_state={backward_state} />

      {title && (
        <Container className="private-site__title">
          <h1>{title}</h1>
        </Container>
      )}

      {code && <ResultMessageView code={code} />}

      {/** コンテンツ表示（メイン） */}
      <div>{children}</div>

      {isToTop && (
        <Container className="backward-link">
          <Row>
            <Col>
              <Link to="/home">TOPに戻る</Link>
            </Col>
          </Row>
        </Container>
      )}

      {/** ローディング表示（全画面にオーバーレイ） */}
      {loading && <FullLoadingView />}
    </>
  );
};
