import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RequestStep } from 'ui/organism/RequestStep';
import { PrivateSite } from 'ui/template/PrivateSite';
import { Col, Container, Row, Table } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { ContractBankView } from 'ui/organism/ContractBank';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { MaintRequest } from 'core/model/contract/MaintRequest';
import { Amount } from 'ui/molecule/Amount';
import './style.scss';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { MaintRequestItem } from 'core/model/contract/MaintRequestItem';
import { MaintRequestResponse } from 'core/model/contractors-api/MaintRequest';

type NavigateState = {
  request_id: string;
};

export const MaintRequestResult = () => {
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const [cookies] = useCookies(['contract']);
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [request, setRequest] = useState<MaintRequest>();
  const [items, setItems] = useState<MaintRequestItem[]>([]);

  /**
   * ログイン情報を取得
   */
  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
    }
  }, []);

  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<MaintRequestResponse[]>({
          path: CONTRACTORS_API_PATH.MAINT_REQUEST,
          params: {
            member_id,
            contract_id,
            request_id: navigateState.request_id,
          },
          member_id,
        });
        if (await api.get()) {
          const response = api.getResponse();
          if (response) {
            setRequest(response[0].request);
            setItems(response[0].items);
          }
        }
      })();
    }
  }, [member_id]);

  return (
    <PrivateSite title="メンテナンス費用請求">
      <Container>
        <Row>
          <Col>
            <RequestStep step={3} />
          </Col>
        </Row>
      </Container>

      <Container>
        <p className="font-body">
          請求は毎週土曜日・月曜日に締切を設けております。
          <br />
          締切日までに送付いただいた請求内容を定額カルモくんにて確認の上、問題がございませんでしたら締切日から数えて4営業日後に、定額カルモくんからお客様の指定銀行口座に料金をお振込いたします。
        </p>
        <p className="font-annotation mt-3">
          ※営業日は当サービス営業日に準じます。
        </p>
      </Container>

      <Container className="mb-3">
        <p className="hint">請求状況の確認や修正をしたい場合</p>
        <p className="font-body">
          メンテナンス請求状況のページで請求内容の確認・修正ができます
        </p>
      </Container>

      <Container className="result">
        <Row>
          <Col>
            <div className="headline">請求内容</div>
          </Col>
        </Row>

        {!request ? (
          <PartLoadingView />
        ) : (
          <>
            <Row className="result__usedate">
              <Col>
                <p>請求日時</p>
              </Col>
              <Col>
                <p>{request.request_date}</p>
              </Col>
            </Row>

            <Row className="result__usedate">
              <Col>
                <p>メンテナンス実施日</p>
              </Col>
              <Col>
                <p>{request.use_date}</p>
              </Col>
            </Row>
          </>
        )}

        {items.length === 0 ? (
          <PartLoadingView />
        ) : (
          <Row>
            <Col>
              <Table className="result__item">
                <thead>
                  <tr>
                    <td>メンテナンス実施項目</td>
                    <td>上限金額</td>
                  </tr>
                </thead>
                <tbody>
                  {items.map((row, index) => {
                    return (
                        <tr key={`maint-item-${index}`}>
                          <td>{row.item_name}</td>
                          <td>
                            {
                              row.max_amount_valid_flg === 1
                                ? <Amount amount={row.max_amount}/>
                                : <span>なし</span>
                            }
                          </td>
                        </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Container>

      <ContractBankView />
    </PrivateSite>
  );
};
