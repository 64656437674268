import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Form,
  Button,
  FormGroup,
  ListGroup,
  Badge,
  Modal,
  Container,
  Col,
  Row,
} from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ContractAPI } from 'compornents/classes/ContractAPI';
import { CONTRACTORS_API_PATH } from 'core/const/ContractorsApiPath';
import { decCookieContract } from 'compornents/functions/util/Cookie';
import { PrivateSite } from 'ui/template/PrivateSite';
import { RequestStep } from 'ui/organism/RequestStep';
import { ImageControl } from 'ui/organism/Form/ImageControl';
import { ContractBankForm } from 'ui/organism/Form/ContractBank';
import help from 'contents/images/icon/help.svg';
import { DISTRIBUTOR } from 'core/const/Distributor';
import { ContractBankView } from 'ui/organism/ContractBank';
import { PartLoadingView } from 'ui/organism/Loading/Part';
import { ContractMaintItem } from 'core/model/contract/ContractMaintItem';
import { format } from 'date-fns';
import { isInvalidDate } from 'compornents/functions/util/Date';
import { DEPOSIT_CODE } from 'core/const/DepositCode';
import { MaintRequestResponse } from 'core/model/contractors-api/MaintRequest';
import { RESULT_CODE } from 'core/const/ResultCode';
import { VALIDATION_MESSEGE } from 'core/const/ValidationMessege';
import './style.scss';

type NavigateState = {
  item_ids: string[];
  receiptFiles: File[];
  use_date: string;
  is_registered_bank?: boolean;
  bank_name?: string;
  branch_name?: string;
  deposit_code?: number;
  account_holder?: string;
  account_number?: string;
  code?: string;
};

type InputForm = {
  item_ids: string[];
  use_date: string;
  is_registered_bank?: boolean;
  bank_name?: string;
  branch_name?: string;
  deposit_code?: number;
  account_holder?: string;
  account_number?: string;
};

type DispMaintItem = {
  id: number;
  maint_item_id: number;
  name: string;
  detail: string;
  annotation?: string;
  max_amount: number;
  max_amount_valid_flg: number;
};

type MaintItemModal = {
  isShow: boolean;
  name: string | null;
  detail: string | null;
  annotation?: string | null;
};

export const MaintRequestEditInput = () => {
  const navigate = useNavigate();
  const { request_id } = useParams();
  const location = useLocation();
  const navigateState = location.state as NavigateState;
  const [cookies] = useCookies(['contract']);
  const [items, setItems] = useState<DispMaintItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<DispMaintItem[]>([]);
  const [maintItemModal, setMaintItemModal] = useState<MaintItemModal>({
    isShow: false,
    name: null,
    detail: null,
    annotation: null,
  });
  const [member_id, setMemberId] = useState<string>();
  const [contract_id, setContractId] = useState<string>();
  const [distributor, setDistributor] = useState<number>();
  const [receiptFiles, setReseptFiles] = useState<File[]>(
    navigateState?.receiptFiles || [],
  );
  const [itemLoaded, setItemLoaded] = useState<boolean>(false);
  const [firstLoaded, setFirstLoaded] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const max_recipt = parseInt(process.env.REACT_APP_MAX_RECEPT_FILES || '5');
  const [expired, setExpired] = useState<boolean>(false);

  useEffect(() => {
    const cookieInfo = decCookieContract(cookies.contract);
    if (cookieInfo) {
      setMemberId(cookieInfo.member_id);
      setContractId(cookieInfo.contract_id);
      setDistributor(cookieInfo.distributor);
    }
  }, []);

  /**
   * 請求中の情報取得
   */
  useEffect(() => {
    if (member_id) {
      (async () => {
        const api = new ContractAPI<MaintRequestResponse[]>({
          path: CONTRACTORS_API_PATH.MAINT_REQUEST,
          params: { member_id, contract_id, request_id },
          member_id,
        });
        if (await api.get()) {
          const response = api.getResponse();
          if (response && response.length === 1) {
            setSelectedItems(
              response[0].items.map((row) => {
                return {
                  id: row.contract_maint_item_id,
                  maint_item_id: row.maint_item_id,
                  name: row.item_name,
                  detail: row.item_detail,
                  annotation: row.item_annotation,
                  max_amount: row.max_amount,
                  max_amount_valid_flg: row.max_amount_valid_flg,
                };
              }),
            );
          } else {
            navigate('/maint', {
              state: { code: RESULT_CODE.MAINT_REQUEST_NOTFOUND },
            });
          }
        }
      })();
    }
  }, [member_id]);

  /**
   * バリデーション
   */
  const validationSchema = yup.object().shape({
    use_date: yup.date().required(VALIDATION_MESSEGE.REQUIRED_MAINT_DATE),
    item_ids: yup.array().required(VALIDATION_MESSEGE.REQUIRED_MAINT_ITEM_ID),
    deposit_code: yup
      .string()
      .matches(/^(1|2|3|9)$/, VALIDATION_MESSEGE.UNMATCH_DEPOSIT_CODE),
    account_holder: yup
      .string()
      .matches(/^[ｦ-ﾟ]+$/, VALIDATION_MESSEGE.UNMATCH_ACCOUNT_HOLDER),
    account_number: yup
      .number()
      .typeError(VALIDATION_MESSEGE.UNMATCH_ACCOUNT_NUMBER),
  });

  /**
   * サブミット
   */
  const onSubmit = (event: InputForm) => {
    navigate(`/maint/edit/${request_id}/confirm`, {
      state: {
        item_ids: event.item_ids,
        receiptFiles,
        use_date: event.use_date,
        is_registered_bank: event.is_registered_bank,
        bank_name: event.bank_name,
        branch_name: event.branch_name,
        deposit_code: event.deposit_code,
        account_holder: event.account_holder,
        account_number: event.account_number,
      },
    });
  };

  const formik = useFormik({
    initialValues: {
      use_date: navigateState.use_date || '',
      item_ids: navigateState.item_ids || [],
      is_registered_bank: navigateState?.is_registered_bank || false,
      bank_name: navigateState?.bank_name || '',
      branch_name: navigateState?.branch_name || '',
      deposit_code: navigateState?.deposit_code || DEPOSIT_CODE.ORDINARY,
      account_holder: navigateState?.account_holder || '',
      account_number: navigateState?.account_number || '',
    },
    onSubmit,
    validationSchema,
  });

  /**
   * メンテ情報を取得
   */
  useEffect(() => {
    if (!member_id || formik.errors.use_date || selectedItems.length === 0) {
      return;
    }
    const date = new Date(formik.values.use_date);
    if (isInvalidDate(date)) {
      return;
    }

    setItemLoaded(false);
    setItems([]);

    if (firstLoaded) {
      formik.values.item_ids = [];
    } else {
      setFirstLoaded(true);
    }

    const use_date = format(date, 'yyyyMMdd');
    (async () => {
      const api = new ContractAPI<ContractMaintItem[]>({
        path: CONTRACTORS_API_PATH.CONTRACT_MAINT,
        params: {
          member_id,
          contract_id,
          use_date,
        },
        member_id,
      });
      const result = await api.get();
      if (result.success && result.statusCode === 200) {
        const response = api.getResponse();
        if (response) {
          const items: DispMaintItem[] = response.map((row) => {
            return {
              id: row.contract_maint_item_id,
              maint_item_id: row.maint_item_id,
              name: row.item_name,
              detail: row.item_detail,
              annotation: row.item_annotation,
              max_amount: row.max_amount,
              max_amount_valid_flg: row.max_amount_valid_flg,
            };
          });
          const all_items = selectedItems.concat(items);
          const unique_items = all_items.filter(
            (item, index, self) =>
              self.findIndex((e) => e.maint_item_id === item.maint_item_id) ===
              index,
          );
          setItems(unique_items);
        }
      } else if (result.statusCode === 401) {
        setExpired(true);
      }
      setItemLoaded(true);
    })();
  }, [formik.values.use_date, selectedItems]);

  /**
   * フォームの変更を監視
   * 必須入力項目が全て入力されているかを確認
   */
  useEffect(() => {
    setSubmitDisabled(true);
    if (
      formik.values.use_date &&
      formik.values.item_ids.length > 0 &&
      receiptFiles.length > 0
    ) {
      if (distributor === DISTRIBUTOR.OAC) {
        setSubmitDisabled(false);
        return;
      } else {
        if (!formik.values.is_registered_bank) {
          if (
            formik.values.bank_name &&
            formik.values.branch_name &&
            formik.values.deposit_code &&
            formik.values.account_holder &&
            formik.values.account_number
          ) {
            if (Object.keys(formik.errors).length === 0) {
              setSubmitDisabled(false);
            }
            return;
          }
        } else {
          setSubmitDisabled(false);
          return;
        }
        return;
      }
    }
    setSubmitDisabled(true);
  }, [formik.values, formik.errors, receiptFiles, firstLoaded]);

  return (
    <>
      <PrivateSite
        title="メンテナンス請求"
        code={navigateState?.code}
        expired={expired}
      >
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Container className="maint-request">
            <Row>
              <Col>
                <RequestStep step={1} />
              </Col>
            </Row>

            <Row className="maint-request__column">
              <Col>
                <Form.Group>
                  <Form.Label>
                    <Badge bg="danger">必須</Badge> メンテナンス実施日
                  </Form.Label>
                  <Form.Control
                    type="date"
                    isInvalid={
                      !!formik.touched.use_date && !!formik.errors.use_date
                    }
                    {...formik.getFieldProps('use_date')}
                  />
                  {formik.touched.use_date && formik.errors.use_date && (
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.use_date}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
                <p className="caution">
                  ※明細書・レシートに記載の日付を正しく選択してください
                </p>
              </Col>
            </Row>

            <Row className="maint-request__column">
              <Col>
                <Form.Group>
                  <Form.Label>
                    <Badge bg="danger">必須</Badge> 明細書写真の添付（最大
                    {max_recipt}枚）
                  </Form.Label>
                  <ImageControl
                    selectedFiles={receiptFiles}
                    setFiles={setReseptFiles}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="maint-request__column">
              <Col>
                <FormGroup>
                  <Form.Label>
                    <Badge bg="danger">必須</Badge> メンテナンス実施項目
                  </Form.Label>
                  <ListGroup className="maint-request__item">
                    <ListGroup.Item className="title">
                      <p>項目（複数選択可能）</p>
                      <p className="overview">
                        概要
                        <br />
                        説明
                      </p>
                    </ListGroup.Item>

                    {!formik.values.use_date ? (
                      <Row>
                        <Col>
                          <p>
                            メンテナンス実施日を入力すると項目が表示されます。
                          </p>
                        </Col>
                      </Row>
                    ) : !itemLoaded ? (
                      <PartLoadingView />
                    ) : items.length > 0 ? (
                      <>
                        {items.map((row, index) => {
                          return (
                            <ListGroup.Item
                              className="row-item"
                              key={`maint-item-${index}`}
                            >
                              <label>
                                <Form.Control
                                  type="checkbox"
                                  name="item_ids"
                                  onChange={formik.handleChange}
                                  value={row.id}
                                  defaultChecked={formik.values.item_ids.some(
                                    (id) => id === String(row.id),
                                  )}
                                />
                                <div className="row-item__detail">
                                  <p>{row.name}</p>
                                  <p className="max-amount">
                                    上限金額：
                                    {row.max_amount_valid_flg === 1
                                      ? `¥${row.max_amount.toLocaleString()}`
                                      : 'なし'}
                                  </p>
                                </div>
                              </label>
                              <label>
                                <img src={help} alt={row.name} />
                                <Button
                                  onClick={() =>
                                    setMaintItemModal({
                                      isShow: true,
                                      name: row.name,
                                      detail: row.detail,
                                      annotation: row.annotation,
                                    })
                                  }
                                />
                              </label>
                            </ListGroup.Item>
                          );
                        })}
                      </>
                    ) : (
                      <Row>
                        <Col>
                          <p>利用できるメンテナンス項目はありません。</p>
                        </Col>
                      </Row>
                    )}
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row>

            <Row className="maint-request__annotation">
              <Col>
                <p>
                  ※お振込金額につきましては、ご請求いただきました内容を確認の上、後ほど項目ごとの上限金額範囲内にて決定し、振込予定日とあわせてご案内いたします。
                </p>
              </Col>
            </Row>
          </Container>

          {distributor === DISTRIBUTOR.OAC ? (
            <ContractBankView />
          ) : (
            <ContractBankForm formik={formik} />
          )}

          <Container>
            <Row>
              <Col>
                <div className="text-center">
                  <Button type="submit" disabled={submitDisabled}>
                    確認する
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      </PrivateSite>

      {/** メンテ項目詳細の全画面表示モーダル */}
      <Modal
        show={maintItemModal.isShow}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() =>
          setMaintItemModal({
            isShow: false,
            name: null,
            detail: null,
            annotation: null,
          })
        }
        className="maint-item-modal"
      >
        <Modal.Header className="maint-item-modal__name" closeButton>
          {maintItemModal.name}
        </Modal.Header>
        <Modal.Body>
          <div className="maint-item-modal__detail">
            {maintItemModal.detail}
          </div>
          {maintItemModal.annotation && (
            <div className="maint-item-modal__annotation">
              ※{maintItemModal.annotation}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
