import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ScrollTop } from 'ui/molecule/ScrollTop';
import { Login } from 'ui/pages/Login';
import { LoginSelect } from 'ui/pages/Login/Select';
import { Logout } from 'ui/pages/Logout';
import { Home } from 'ui/pages/Home';
import { ContractConfirm } from 'ui/pages/Contract';
import { ContractSelect } from 'ui/pages/Contract/ContractSelect';
import { BillingConfirm } from 'ui/pages/Billing';
import { MaintRequestTop } from 'ui/pages/Maint';
import { MaintRequestDetail } from 'ui/pages/Maint/Detail';
import { MaintRequestCreateInput } from 'ui/pages/Maint/Request/Create/Input';
import { MaintRequestCreateConfirm } from 'ui/pages/Maint/Request/Create/Confirm';
import { MaintRequestCreateExecute } from 'ui/pages/Maint/Request/Create/Execute';
import { MaintRequestResult } from 'ui/pages/Maint/Request/Result';
import { MaintRequestCancel } from 'ui/pages/Maint/Request/Cancel';
import { MaintRequestEditInit } from 'ui/pages/Maint/Request/Edit/Init';
import { MaintRequestEditInput } from 'ui/pages/Maint/Request/Edit/Input';
import { MaintRequestEditConfirm } from 'ui/pages/Maint/Request/Edit/Confirm';
import { MaintRequestEditExecute } from 'ui/pages/Maint/Request/Edit/Execute';
import { MaintHistoryPage } from 'ui/pages/Maint/History';
import { MaintHistoryDetail } from 'ui/pages/Maint/History/Detail';
import { MaintMerchantPage } from 'ui/pages/Maint/Merchant';
import { ContractMaintMerchantPage } from 'ui/pages/Contract/Merchant';
import { CampaignPage } from 'ui/pages/Campaign';
import { NoticePage } from 'ui/pages/Notice';
import { InitPasswordSetting } from 'ui/pages/Password/Init';
import { PasswordSetting } from 'ui/pages/Password/Setting';
import { PasswordReset } from 'ui/pages/Password/Reset';
import { PasswordResetResult } from 'ui/pages/Password/Reset/Result';
import { PasswordResetSetting } from 'ui/pages/Password/Reset/Setting';
import { BenefitPage } from 'ui/pages/Benefit';
import { Contact } from 'ui/pages/Contact';
import { SystemMaintenance } from 'ui/pages/SystemMaintenance';
import { props } from 'core/Props';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { BackdoorLogin } from 'ui/pages/Login/Backdoor';
import { APP_MODE } from 'core/const/AppMode';
import { InitPasswordSettingExecute } from 'ui/pages/Password/Init/Execute';
import { PasswordSettingExecute } from 'ui/pages/Password/Setting/Execute';

function App() {
  const is_backdoor = props.APP_MODE === APP_MODE.BACKDOOR;
  const basename = is_backdoor ? '/backdoor/app' : '/app';

  return (
    <>
      <BrowserRouter basename={basename}>
        <ScrollTop />
        <Routes>
          {is_backdoor ? (
            <Route path="/login" element={<BackdoorLogin />} />
          ) : (
            <Route path="/login" element={<Login />} />
          )}

          <Route path="/login/select" element={<LoginSelect />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/home" element={<Home />} />
          <Route path="/contract" element={<ContractConfirm />} />
          <Route path="/contract/select" element={<ContractSelect />} />
          <Route
            path="/contract/merchant"
            element={<ContractMaintMerchantPage />}
          />
          <Route path="/billing" element={<BillingConfirm />} />
          <Route path="/maint" element={<MaintRequestTop />} />
          <Route path="/maint/input" element={<MaintRequestCreateInput />} />
          <Route
            path="/maint/confirm"
            element={<MaintRequestCreateConfirm />}
          />
          <Route
            path="/maint/execute"
            element={<MaintRequestCreateExecute />}
          />
          <Route path="/maint/result" element={<MaintRequestResult />} />
          <Route
            path="/maint/detail/:request_id"
            element={<MaintRequestDetail />}
          />
          <Route
            path="/maint/cancel/:request_id"
            element={<MaintRequestCancel />}
          />
          <Route
            path="/maint/edit/:request_id"
            element={<MaintRequestEditInit />}
          />
          <Route
            path="/maint/edit/:request_id/input"
            element={<MaintRequestEditInput />}
          />
          <Route
            path="/maint/edit/:request_id/confirm"
            element={<MaintRequestEditConfirm />}
          />
          <Route
            path="/maint/edit/:request_id/execute"
            element={<MaintRequestEditExecute />}
          />
          <Route path="/maint/history" element={<MaintHistoryPage />} />
          <Route
            path="/maint/history/detail/:maint_history_id"
            element={<MaintHistoryDetail />}
          />
          <Route path="/merchant" element={<MaintMerchantPage />} />
          <Route path="/campaign" element={<CampaignPage />} />
          <Route path="/notice" element={<NoticePage />} />
          <Route path="/benefit" element={<BenefitPage />} />
          <Route path="/password/init" element={<InitPasswordSetting />} />
          <Route
            path="/password/init/execute"
            element={<InitPasswordSettingExecute />}
          />
          <Route path="/password/setting" element={<PasswordSetting />} />
          <Route
            path="/password/setting/execute"
            element={<PasswordSettingExecute />}
          />
          <Route path="/reset" element={<PasswordReset />} />
          <Route path="/reset/result" element={<PasswordResetResult />} />
          <Route
            path="/reset/a/:reset_uuid"
            element={<PasswordResetSetting />}
          />

          <Route path="/maintenance" element={<SystemMaintenance />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
